/** 
 * NAV-SUPERVISORS
 * Container for supervisors interface
 */

import { connect } from "react-redux"

import { withTranslation, WithTranslation } from "react-i18next"
import NavItem from "@/components/nav-item"
import { faComment, faFileAlt, faGraduationCap, faPencilAlt, faShieldAlt, faUserCog } from "@fortawesome/free-solid-svg-icons"
import Space from "@/components/space"
import logout from "@/utils/logout.utils";
import NavAvatar from "@/components/nav-avatar"
import { Session, SESSION_LANGUAGES } from "@/redux/_session.types"
import Chip from "@/components/chip"
import i18n from "@/translate/i18n"
import { store } from "@/index"
import { sessionEdit } from "@/redux/_session.actions"
import { supervisorUpdateLanguage } from "@/redux/supervisor.actions"

interface Props extends WithTranslation{
  _session : Session
  children : any
}

function NavSupervisor(props:Props) {
  const { t } = props

  function setLanguage(lg){
    i18n.changeLanguage(lg.id)
    store.dispatch(sessionEdit("language", lg.id))
    store.dispatch(supervisorUpdateLanguage(lg.id))
  }

  return (
    <div>

      { /** header */}
      <div className="flex page-navigation-menutop grey-bor"
        style={{ background : 'linear-gradient(90deg, rgb(0, 203, 123) 0%, rgb(81, 232, 153) 100%)' }}>

        { /* idtree icon */ }
        <img src={require('@/assets/logo-sm.png')} 
          className="page-navigation-logo" alt="logo">
        </img>

        <NavItem icon={faShieldAlt}
          pathname="/supervisor/accounts"
          title={t("accounts")}>
        </NavItem>
        
        { props._session.supervisorLevel > 1 &&
        <NavItem icon={faPencilAlt}
          pathname="/supervisor/templates"
          title={t("templates")}>
        </NavItem>
        }

        { props._session.supervisorLevel > 1 &&
        <NavItem icon={faFileAlt}
          pathname="/supervisor/reports"
          title={t("reports")}>
        </NavItem>
        }

        { props._session.supervisorLevel > 1 &&
        <NavItem icon={faGraduationCap}
          pathname="/supervisor/lms"
          title={t("learning")}>
        </NavItem>
        }

        { props._session.supervisorLevel > 2 &&
        <NavItem icon={faUserCog}
          pathname="/supervisor/users"
          title={t("supervisors")}>
        </NavItem>
        }
        
        <NavItem icon={faComment}
          pathname="/supervisor/feedbacks"
          title={t("feedbacks")}>
        </NavItem>

        <Space/>

        <div className="flex"
          style={{ margin : '8px 16px' }}>

          { SESSION_LANGUAGES.map(lg =>
          <Chip key={lg.id}
            onClick={props._session.language !== lg.id ? () => setLanguage(lg) : undefined}
            color={props._session.language === lg.id ? "#ff9500" : "#20CA7E"}
            isWithMargin>
            {lg.value}
          </Chip>
          )
          }
        
        </div>
        
        <NavAvatar>
          <div className="flex page-navigation-subitem" onClick={() => logout(false)}> 
            <div className="context-menu-item">
              {t("utils_logout")}
            </div>
          </div>
        </NavAvatar>

      </div>

      <div className="flex page-navigation page-navigation-withheader">
        <div id="page-navigation-scrollable"
          className="flex1 page-navigation-children page-navigation-scrollable flex flex-dcol"
          style={{ height : 'calc(100vh - 88px)' }}>
          { props.children }
        </div>
      </div>

    </div>
  )
  
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(NavSupervisor))