/** 
 * POPULATION.TYPE
 * Name of population for a given filter
 */

//Population list
export class Population{
  isTemplatePopulation : boolean = false //Is population created by the template (axis introduction)
  filterName : string = "" //Name of the filter
  id : string = "" //Id for the population (concat of filter_name and name)
  aid? : any = null //Archive id of the population
  name : string = "" //Name for the population
  constructor(item: Partial<Population> = {}){
    Object.assign(this, item)
  }
}