/** 
 * NO-RESULTS.WIDGET
 * Page placeholder if not enough results
 */
import PagePlaceholder from "@/components/page-placeholder"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps extends WithTranslation{
  _session : Session
}

function NoResultsWidget(props:StateProps) {
  const { t } = props
  return (
    <PagePlaceholder
      title={t("survey_no_results")}
      image="sherlock"
      help={t("survey_no_results_help", {
        count : props._session.participationMin
      })}
    />
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(NoResultsWidget))