/** 
 * SESSION 
 * Informations related to the session of an account
 */

import { AccountAdsOptions, AccountColors, AccountLevel, AccountModules, AccountOptions, AccountTemplateRule } from "./account.types"
import { UserOptions, USER_ROLE } from "./user.types"
import languages from '@/translate/lang.json'
import { sortBy } from "lodash"
import env from "@/env"
import { getIconUrl } from "@/utils/default-icons.utils"
import { t } from "@/translate/t"

export type CONNEXION_MODE = "GOOGLE" | "EMAIL" | "SMS"
export type INTERFACE_TYPES = "ADMIN" | "SUPERADMIN" | "SUPERVISOR"

export const SESSION_DEFAULT_LANGUAGES = ["en", "fr"]
export type SessionDefaultLanguage = typeof SESSION_DEFAULT_LANGUAGES[number]

//Information of the idtree contact
export interface SessionSalesContact{
  name : string
  description : string
  phone : string
  imageUrl : string
  email : string
  calendly : string
  isActive : boolean
}

export class Session{
  accountAdsOptions : AccountAdsOptions = new AccountAdsOptions()
  accountColors : AccountColors = new AccountColors() //Colors for connected account
  accountId : string = "" //Id of the connected account
  accountIsChildren : boolean = false //The account depends of a super_account
  accountLastSubscriptionEnd : number | null = null //Expiration date of last subscription 
  accountLevel : AccountLevel = "free" //Level of the account
  accountOptions : AccountOptions = new AccountOptions() //Options (image, preferences...)
  accountTemplateRule : AccountTemplateRule = "all" //Permissions for the templates
  accountAllowedTemplates : string[] = [] //Permissions for the templates
  accountName : string = "" //Name of the connected account
  authLevel : number = 0 //Auth Level (0 => no auth, 1 => email, 2 => password)
  connectedAsSuperadmin : boolean = false //Is the user connected a superadmin
  connectedAsSupervisor : boolean = false //Is the user connected a supervisor
  connexionMode : CONNEXION_MODE = "EMAIL" //TYpe of connexion
  email : string = "" //Email of the connected user
  errorMessage : string | null = null //Display Error message on the bottom of the screen
  isPasswordRequired : boolean = false //Is password required for advanced roles (EDITOR and OBSERVER)
  isSelfService : boolean = false //The customer is come by his own way
  isSuperAccount : boolean = false //Consulting account
  interfaceType : INTERFACE_TYPES = "ADMIN" //Type of interface (define the level of the app)
  invitationsRemaining : number = 0 //Count of remaining invitations
  language : SessionDefaultLanguage = "fr" //Current language displayed
  logoLastUpdate : number = new Date().getTime() //Refresh image cache when image is updated
  modules : AccountModules = new AccountModules() //List of modules
  participationMin : number = 5 //Ceil to display results
  phone : string = "" //User's phone number
  qvCode : string | null = null //Code for voting
  salesContact : SessionSalesContact = getRandomSalesContact()
  superaccountColor : string = "" //Color for the superaccount 
  superaccountId : string = "" //Id of superaccount 
  superaccountImage : boolean = true //Has the superaccount an image attached
  superaccountName : string = "" //Name for the superaccount 
  supervisorId : string = "" //Id of the supervisor connected
  supervisorLevel : number = 0 //level of the supervisor connected
  userPasswordProvided : boolean = false //Has the user provided a password
  userId : string = "" //Id of the connected user
  userExcludedFilters : string[] = [] //List of filtres not visible for the user in the dashboard
  userFirstname : string = "" //Firstname of the connected user
  userInitialLanguage : string = "en" //Language if different of fr or en (for displaying texts)
  userLastname : string = "" //lastname of the connected user
  userOptions : UserOptions = new UserOptions() //Options of the user (preferences for app display)
  userRole : USER_ROLE | null = null //Connected user role "ADMIN" | "EDITOR" | "OBSERVER" | null
  
  constructor(session: Partial<Session> = {}){
    if (!session) session = new Session()
    session.accountColors = new AccountColors(session.accountColors)
    session.accountOptions = new AccountOptions(session.accountOptions)
    session.userOptions = new UserOptions(session.userOptions)
    Object.assign(this, session)
  }
  
  //Return settings options
  //If specific settings are available apply them
  get dashboardDisplaySettings():AccountOptions{
    if (this.userOptions.dashboardApplyUserSettings && this.userRole === "ADMIN"){
      return new AccountOptions(this.userOptions)
    }else if (this.userOptions.dashboardApplyUserSettings && this.accountOptions.allowObserverSettings){
      return new AccountOptions(this.userOptions)
    }else{
      return this.accountOptions
    }
  }

  //List of languages avaiable
  //First value : language for the account
  //Second value : english
  get languagesAvailables():any[]{
    
    let list:any[] = languages.filter(x => x.code !== this.language && x.code !== 'en')
    list = list.map(x => { return { id : x.code, name : x.name.split(";")[0].split(",")[0] + " (" + x.code.toUpperCase() + ")" }})
    list = sortBy(list, ["name"])
    
    if (this.language !== 'en'){
      list.unshift({ id : 'en', name : 'EN (' + t("utils_default") + ')' })
    }

    list.unshift({ id : this.language, name : this.language.toUpperCase() + ' (' + t("utils_default") + ')' })
    
    return list

  }

  get userName():string{
    if (this.userFirstname || this.userLastname){
      return this.userFirstname + " " + this.userLastname
    }else{
      return this.email.split('@')[0]
    }
  }

  get accountImage(): string{
    return this.accountOptions.image ?
      env.REACT_APP_URL_SPACE.concat("/Accounts/", this.accountId, ".png?" + this.logoLastUpdate)
    :
      getIconUrl(this.accountOptions.defaultIcon)
  }

}

export const SESSION_LANGUAGES = [
  { id : 'fr', value : 'FR' },
  { id : 'en', value : 'EN' }
]

//Colors for picker (all colors)
export const SESSION_COLORS = [
	"#0079BF",
	"#094C72",
	"#61BD4F",
	"#49852E",
	"#FFAB4A",
	"#E99E40",
	"#EB5A46",
	"#933B27",
	"#F2D600",
	"#E6C60D",
	"#C377E0",
	"#6C547B",
	"#FF80CE",
	"#B44772",
	"#00C2E0",
	"#0082A0",
	"#51E898",
	"#4FD683",
	"#C4C9CC",
	"#B6BBBF",
	"#42548E",
	"#36405F"
]

//Pick a sales at random
function getRandomSalesContact(){

  let salesContacts: SessionSalesContact[] = [
    {
      name : "Thomas Röhm",
      description : "Expert QVT et co-fondateur de IDTree",
      phone : "09 52 16 91 28",
      imageUrl : "https://idtree.fra1.digitaloceanspaces.com/Website/thomas.png",
      email : "thomas.rohm@id-tree.com",
      calendly : "https://calendly.com/idtree-thomas/reunion-15-minutes?utm_source=idtree_app",
      isActive : true
    },
    {
      name : "Samy Atmani",
      description : "Expert QVT",
      phone : "07 45 13 39 73",
      imageUrl : "https://idtree.fra1.digitaloceanspaces.com/Website/samy.png",
      email : "samy.atmani@id-tree.com",
      calendly : "https://calendly.com/samy-atmani-id-tree/40min?utm_source=form_contact?utm_source=idtree_app",
      isActive : true
    }
  ]

  salesContacts = salesContacts.filter(x => x.isActive)
  if (salesContacts.length === 1){
    return salesContacts[0]
  }else{
    const random = Math.random()
    if (random < 0.50){
      return salesContacts[0]
    }else{
      return salesContacts[1]
    }
  }

}

export const SESSION_EDIT = 'SESSION_EDIT'
export const SESSION_EDIT_ACCOUNT_COLORS = 'SESSION_EDIT_ACCOUNT_COLORS'
export const SESSION_EDIT_ACCOUNT_OPTIONS = 'SESSION_EDIT_ACCOUNT_OPTIONS'
export const SESSION_EDIT_USER_OPTIONS = 'SESSION_EDIT_USER_OPTIONS'
export const SESSION_SHOW_ERROR = 'SESSION_SHOW_ERROR'
export const SESSION_GET = 'SESSION_GET'
export const SESSION_INIT = 'SESSION_INIT'