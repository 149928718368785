/**
 * DASHBOARD-REPARTITION-BARS.WIDGET
 */

import { max, sum } from "lodash"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Session } from "../redux/_session.types"
import ReactTooltip from 'react-tooltip'
import { withTranslation, WithTranslation } from "react-i18next"
import Space from "../components/space";
import { AccountOptions } from "@/redux/account.types"
import getRepartitionColor from "@/utils/get-repartition-color.utils"
import { getRepartitionRatio } from "@/utils/satisfaction.utils"
import { responseLabel } from "@/utils/response-label.utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

interface StateProps{
  _session: Session
}

interface OwnProps{
  values: number[]
  classicColors?: boolean
  questionNpsAid?: number
  displaySatisfaction?: boolean
  isReverse?: boolean
  showSubtext?: boolean
  showCeils?: boolean
}

type Props = StateProps & OwnProps & WithTranslation

//For specific client
//Alternative display for dashboard
const ACANTE_MAGIC_BACKEND = [
  "6fec5604-588c-463f-8800-e252de6e647c", //INITIAL
  "ae0289c8-0bd8-48f4-9079-593d0491589a", //IHS
  "4466758d-a175-4a04-bba9-786e30fefaa7" //ALTRAD
]

function DashboardRepartitionBarsWidget(props: Props){
  const { t } = props

  const [ceils] = useState(props.questionNpsAid ? [6, 8] : props._session.dashboardDisplaySettings.ceilsRepartition)

  const [values, setValues] = useState<number[]>(props.values)
  const [valuesSum, setValuesSum] = useState<number>(0)
  const [valueMax, setValueMax] = useState<number>(1)

  useEffect(() => {

    if(props.values.length > 0){
      setValuesSum(sum(props.values))
      setValueMax(max(props.values)!)
      setValues(props.values)
    }
    else{
      setValueMax(1)
      setValues([])
    }
  }, [
    props.values
  ])

  //Style
  function getStyle(value: number, i: number){
    let height = 0
    let backgroundColor = ""

    if (valueMax > 0){
      height = (value / valueMax) * 100
      if (height < 2 && height > 0) height = 2
    }

    if(props.classicColors){
      backgroundColor = getRepartitionColor((i >= 2 && values.length === 4) ? i + 1 : i, values.length, false, new AccountOptions(), props.values.length === 10)
    }
    else if (i + 1 > (props.showCeils ? ceils[1] : props._session.dashboardDisplaySettings.ceilsRepartition[1])){
      //green
      backgroundColor = "#20CA7E"
    }else if (i + 1 <= (props.showCeils ? ceils[0] : props._session.dashboardDisplaySettings.ceilsRepartition[0])){
      //red
      backgroundColor = "#eb5a46"
    }else{
      if (props.questionNpsAid){
        //yellow
        backgroundColor = "#f2d600"
      }else{
        //lightgreen
        backgroundColor = "#99efc2"
      }
    }

    return {
      backgroundColor,
      height : height + "px"
    }

  }

  //Text for tooltip
  function getText(value: number, i: number){
    if(props.classicColors){
      return t("user_questions_count", {
        count : value,
        s : value > 1 ? "s" : "",
        ratio : getRepartitionRatio(value, values).toFixed(1)
      })
    }
    else{
      let j = 0.1

      if (props.displaySatisfaction){
        i = i * 10
        j = j * 10
      }

      return t("survey_repartition_text_" + (props.displaySatisfaction ? "satisfaction" : "note"), { 
        count : value,
        ratio : (value / valuesSum * 100).toFixed(1),
        s : value > 1 ? "s" : "",
        min : (i + j).toFixed(0),
        max : i === 9 ? 10 : (i + j * 10 - 0.1).toFixed(1),
        total : values.length
      })
    }
    
  }

  function getSubtext(index: number): string{
    const responseCount: number = values.length
    return responseLabel(props.isReverse ? responseCount - index : index + 1, responseCount) + ""
  }

  //Get sum by group of people
  function getSum(min, count){
    const total = sum(values.slice(min, count))
    return (total / valuesSum * 100).toFixed(1) + "%"
  }
  
  //Get repartition
  function getRepartitionLabel(min:number, max:number, label:string){
    label = t("survey_repartition_" + label + (props.questionNpsAid ? "_nps" : ""))
    return (
      <div style={{ width : (max - min) * 26 + (max - min - 1) * 16 }}>
        <div className="survey-rps-bottom grey-b"></div>
  
        { max - min > 1
        ?
        <div className="survey-rps-bottom-text">
          {label}
          <br></br>
          <b>{getSum(min, max)}</b>
        </div>
        :
        <div className="survey-rps-bottom-text">
          <FontAwesomeIcon icon={faInfoCircle} className="_hover" data-tip={label + " : " + getSum(min, max)}/>
        </div>
        }
  
      </div>
    )
  }

  return (
    <div>
      <div className="flex">

        { values.map((value: number, i: number) =>
        <div className="flex1"
          key={i}>

          <div className="flex grey-t"
            style={{ fontSize : '12px', marginBottom : '12px' }}>
            <Space />
            {value}
            <Space />
          </div>

          <div className="flex"
            data-tip={getText(value, i)}>
            <div className="repartition-bar flex flex-dcol">
              <Space />
              <div className="repartition-bar"
                style={getStyle(value, i)}>
              </div>
            </div>
          </div>

          { props.showSubtext &&
          <div className="flex" style={{marginTop: 4, fontSize: 10, textAlign: "center"}}>
            <Space/>
            {getSubtext(i)}
            <Space/>
          </div>
          }

        </div>
        )}

      </div>

      { props.showCeils &&
      <>
        { ACANTE_MAGIC_BACKEND.indexOf(props._session.accountId) > -1 
        ?
        <div className="flex">
          {getRepartitionLabel(0, ceils[0], "unhappy_acante")}
          <div style={{ width : 20 }}></div>
          {getRepartitionLabel(ceils[0], 10, "veryhappy_acante")}
        </div>
        :
        <div className="flex">
          <div style={{ width : 8 }}/>
          {getRepartitionLabel(0, ceils[0], "unhappy")}
          <div style={{ width : 14 }}></div>
          {getRepartitionLabel(ceils[0], ceils[1], "happy")}
          <div style={{ width : 14 }}></div>
          {getRepartitionLabel(ceils[1], 10, "veryhappy")}
          <div style={{ width : 8 }}/>
        </div>
        }
      </>
      }

      <ReactTooltip/>
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
})

export default connect(mapStateToProps)(withTranslation()(DashboardRepartitionBarsWidget))
