/** 
 * EDITOR-NAV
 * Navigation on the left side of the screen when editor is in advanced mode
 */
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { store } from "@/index"
import { Topic, TopicState } from "@/redux/topic.types"
import ReactTooltip from 'react-tooltip'
import { topicActivate, topicAdd, topicEdit, topicSwap } from "@/redux/topic.actions"
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { FadeIn } from "@/utils/animations.utils";
import { AxisState } from "@/redux/axis.types"
import Space from "@/components/space"
import { TemplateState } from "@/redux/template.types"
import EditorAxesOptionsWidget from "./editor-axes-options.widget"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClone, faCommentDots, faInfoCircle, faLock, faPlus } from "@fortawesome/free-solid-svg-icons"
import Link from "@/components/link"
import { useState } from "react"
import FilterQuestionsHelpModal from "@/help/filter-questions.help"
import { Session } from "@/redux/_session.types"
import UpgradeModal from "@/modals/upgrade.modal"
import Button from "@/components/button"

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState
  template : TemplateState
  topic : TopicState
}

interface OwnProps{
  onAddAxis : Function
}

type Props = StateProps & OwnProps

const MODAL_HELP_FILTER = "MODAL_HELP_FILTER"
const MODAL_UPGRADE = "MODAL_UPGRADE"

function EditorNav(props:Props) {
  const { t } = props

  const [currentHelp, setCurrentHelp] = useState<string | null>(null)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Add filter question
  //Only if segmentation is active
  function addFilterQuestion(){
    if (props._session.modules.segment){
      store.dispatch(topicAdd(false, null, false, true, !props._session.userOptions.activateQuestions))
    }else{
      setCurrentModal(MODAL_UPGRADE)
    }
  }

  //Is the card active
  function isTopicActive(topicId){
    return topicId === props.topic.active.id
  }

  //Change order between two topics
  function swap(e){
    const source = props.topic.list.find(x => x.id === e.draggableId)
    store.dispatch(topicActivate(source))

    if (e.destination){

      if (e.destination.droppableId.indexOf("axis_") > -1){

        const axis = props.axis.list.find(x => x.id === e.destination.droppableId.split("axis_")[1])
        store.dispatch(topicEdit("Axis", axis))

      }else{

        const destination = props.topic.list.find(x => x.id === e.destination.droppableId)
        if (destination && source){
          store.dispatch(topicSwap(e.draggableId, e.destination.droppableId))
  
          if (destination.Axis?.id !== source.Axis?.id){
            store.dispatch(topicEdit("Axis", destination.Axis))
          }
  
        }
      }

    }
  }

  //Button for adding an axis
  function renderAxisAdd(){
    return (
      <div className="flex editor-nav-axis-button">
        <Space/>
        <Link onClick={() => props.onAddAxis()}
          isWithoutMargin>
          <FontAwesomeIcon icon={faPlus}/>&nbsp;
          {t("axis_add")}
        </Link>
      </div>
    )
  }

  //Axis dropZone
  function renderAxis(axis, axisName, i){
    return (
      <Droppable droppableId={'axis_' + (axis ? axis.id : "")} type="TOPIC">
        {provided => (
        <div ref={provided.innerRef}
          {...provided.droppableProps}>

          { axis
          ?
          <div>

            <b>
              {t("axis_with_number", {
                number : i + 1,
                name : axis.name[props.template.active.language]
              })}
            </b>

          </div>
          :
          <b>
            {axisName}
          </b>
          }

          {provided.placeholder}

        </div>
        )
        }
      </Droppable>
    )
  }

  //Topic
  function renderTopic(topic: Topic, i: number | null, j: number, isDragDisabled: boolean){
    return (
      <Droppable droppableId={topic.id} type="TOPIC" key={topic.id}>
        {provided => (
          <div ref={provided.innerRef}
            {...provided.droppableProps}>

            <Draggable draggableId={topic.id} index={0}
              isDragDisabled={isDragDisabled}>
              {(provided) => (
                <div 
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}>
                    
                  <div className="editor-nav-topic flex"
                    data-multiline
                    onClick={() => store.dispatch(topicActivate(topic))}
                    style={ isTopicActive(topic.id) ?
                      { backgroundColor : topic.Axis ? topic.Axis.color : "#c1c1c1" } : 
                      { backgroundColor : 'white', color : "#8c8c8c" }}>
                        
                    <div style={ isTopicActive(topic.id) ?
                        { color : 'white' } :
                        { color : topic.Axis ? topic.Axis.color : "#c1c1c1" }
                      }>
                      <b>#{i !== null ? i+1 : 'x'}.{j+1}</b>&nbsp;&nbsp;
                    </div>

                    { topic.countDisplayPastille &&
                    <div className="editor-nav-pastille">
                      <div>

                        { topic.countDraft > 0 &&
                        <div
                          className="podium-pastille"
                          data-for={"draft"}
                          data-tip={topic.countLabel}
                          style={{ backgroundColor : "#f2d600" }}
                        />
                        }

                        { topic.countReverse > 0 &&
                        <div
                          className="podium-pastille"
                          data-for={"reverse"}
                          data-tip={topic.countLabel}
                          style={{ backgroundColor : "#0079bf" }}
                        />
                        }

                        {( topic.countRps > 0 && props._session.modules.rps ) &&
                        <div
                          className="podium-pastille orange"
                          data-for={"rps"}
                          data-tip={topic.countLabel}
                        />
                        }

                        {( topic.countNps > 0 && props._session.modules.nps ) &&
                        <div
                          className="podium-pastille green"
                          data-for={"nps"}
                          data-tip={topic.countLabel}
                        />
                        }

                      </div>
                      <ReactTooltip id={"draft"}/>
                      <ReactTooltip id={"reverse"}/>
                      <ReactTooltip id={"rps"}/>
                      <ReactTooltip id={"nps"}/>
                    </div>
                    }

                    <b>{topic.getLabelNav(props.template.active.language, props.topic.list)}</b>

                    { /** show annotations */
                    topic.Annotations.length > 0 &&
                    <FontAwesomeIcon icon={faCommentDots}
                      style={{ marginLeft : 6 }}
                    />
                    }

                    <Space/>

                    { /** show count if more than one question */
                    topic.Questions.length > 1 &&
                    <div style={{ minWidth : '32px' }} className="flex">
                      <Space/>
                      <div data-tip={t("topic_questions_count", {
                          count : topic.Questions.length
                        })}>
                        <FontAwesomeIcon icon={faClone}/>
                        &nbsp;
                        {topic.Questions.length}
                      </div>
                      <ReactTooltip/>
                    </div>
                    }
                    
                </div>
                
              </div>
              )}
            </Draggable>
            
            {provided.placeholder}

        </div>
        )}

      </Droppable>
    )
  }

  return (
    <DragDropContext onDragEnd={swap}>
      <FadeIn className="editor-nav">

        { currentModal === MODAL_UPGRADE &&
        <UpgradeModal onClose={() => setCurrentModal(null)}
          feature="segment"
        />
        }

        { currentHelp === MODAL_HELP_FILTER &&
        <FilterQuestionsHelpModal onClose={() => setCurrentHelp(null)}/>
        }
        
        <div className="grey-t"
          style={{
            textAlign : "center",
            marginTop : 18,
            fontSize : 18
          }}>
          {t("questions_primary_count" + (props.topic.list.length > 1 ? "" : "_unique"), { count : props.topic.list.length })}
        </div>

        
        { !props.template.active.fromSupervisor &&
        <div className="editor-nav-axis">
          
          <div>
            <b>
              {t("axis_introduction")}
            </b>
            &nbsp;
            <FontAwesomeIcon icon={faInfoCircle} 
              className="grey-t _hover"
              onClick={() => setCurrentHelp(MODAL_HELP_FILTER)}
            />
          </div>

          { /* loop on topics for navigation for topics without axis */
          props.topic.list.filter(x => x.isAtStart).map((topic, j) =>
          renderTopic(topic, -1, j, true)
          )
          }
          
          <div className="flex editor-nav-axis-button"
            style={{ margin : 12, marginTop : 28 }}>
            <Space/>
            <Link icon={props._session.modules.segment ? faPlus : faLock}
              onClick={addFilterQuestion}>
              {t("question_add")}
            </Link>
            <Space/>
          </div>

        </div>
        }

        { props.axis.list.map((axis, i) =>
        <div key={axis.id}>
          <div className="editor-nav-axis"
            style={{ borderColor : axis.color }}>

            <div className="flex">
              
              <div style={{ color : axis.color }}>
                {renderAxis(axis, null, i)}
              </div>
              
              <Space/>

              <div className="editor-nav-axis-caret">
                <EditorAxesOptionsWidget currentAxis={axis}/>
              </div>

            </div>

            { /* loop on topics for navigation */
            props.topic.list.filter(x => x.Axis?.id === axis.id).map((topic, j) =>
            renderTopic(topic, i, j, false)
            )
            }

          </div>

          {renderAxisAdd()}

        </div>
        )
        }

        <div className="editor-nav-axis">

          <div className="flex">
            {renderAxis(null, t("questions_without_axis"), 0)}
          </div>

          { /* loop on topics for navigation for topics without axis */
          props.topic.list.filter(x => x.Axis === null && !x.isAtStart).map((topic, j) =>
          renderTopic(topic, null, j, false)
          )
          }

          { props.topic.list.filter(x => x.Axis === null && !x.isAtStart).length === 0 &&
          <div className="grey-t"
            style={{ fontSize : '12px', marginTop : '6px' }}>
            {t("questions_without_axis_help")}
          </div>
          }

          {((props._session.interfaceType === "SUPERVISOR" || !props.template.active.fromSupervisor ) ) &&
          <Button
            isWithBorder
            isFullWidth
            onClick={() => store.dispatch(topicAdd(props._session.interfaceType === "SUPERVISOR", null, true, false, !props._session.userOptions.activateQuestions))}>

            
            <span className="nav-focus-action-large">
              <FontAwesomeIcon icon={faPlus}/>
              {t("question_add")}
            </span>

          </Button>
          }

        </div>

        {renderAxisAdd()}

        <div className="height-100"/> 

      </FadeIn>
    </DragDropContext>
    
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  template : state.template,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(EditorNav))