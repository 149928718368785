//_INDEX.REDUCERS.JS
//Liste de tout les objets manipulés dans l'application

/**
 * COMMENT STRUCTURER LES ACTIONS
 * 

# Convention de nommage pour une action
<reducer><action><attribut?>

# REDUCER => objet à modifier
ex : filter, account

# ACTION => quel opération réaliser sur l'objet
ex filterActivate, accountGet

=== liste des actions possibles ===
activate => sélectionner une ligne pour la rendre active
add => créer une ligne côté front
cancel => annuler une opération
copy => ajouter un user dans la liste à partir d'une liste existante
duplicate => créer un enregistrement en BDD à partir d'un enregistrement actuel
edit => modifier l’enregistrement actif côté front
destroy => supprimer une ligne dans la BDD
fetch => récupérer la liste des utilisateurs dans la BDD
get => mettre à jour la liste côté front
init => initialiser le store
insert => créer une ligne dans la BDD
remove => supprimer une ligne côté front
replace => remplace an item by another one wich the same id
save => sauvegarder les modifications côté front
status => définir le statut
update => mettre à jour la ligne dans la BDD
upload => uploader une image sur le serveur

# ATTRIBUT => paramètre optionnel sur l'action
ex : filterEditName, accountGet_AsSupervisor


*/

import { combineReducers } from 'redux'
import _session from './_session.reducers'
import account from './account.reducers'
import actionPlan from './action-plan.reducers'
import attribute from './attribute.reducers'
import axis from './axis.reducers'
import bookmark from './bookmark.reducers'
import email from './email.reducers'
import filter from './filter.reducers'
import invitation from './invitation.reducers'
import learning from './learning.reducers'
import lesson from './lesson.reducers'
import message from './message.reducers'
import pack from './pack.reducers'
import page from './page.reducers'
import project from './project.reducers'
import proposition from './proposition.reducers'
import question from './question.reducers'
import regrouping from './regrouping.reducers'
import reminder from './reminder.reducers'
import report from './report.reducers'
import sending from './sending.reducers'
import subscription from "./subscription.reducers"
import supervisor from "./supervisor.reducers"
import survey from './survey.reducers'
import template from './template.reducers'
import topic from './topic.reducers'
import user from './user.reducers'

export default combineReducers({
  _session,
  account,
  actionPlan,
  attribute,
  axis,
  bookmark,
  email,
  filter,
  invitation,
  learning,
  lesson,
  message,
  pack,
  page,
  project,
  proposition,
  question,
  regrouping,
  reminder,
  report,
  sending,
  subscription,
  supervisor,
  survey,
  template,
  topic,
  user
})