/***
 * ADM-FILTERS
 * Get list of customer filters and edit them
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import { Session } from "@/redux/_session.types";
import { faCrown, faEye, faInfoCircle, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { store } from "@/index";
import Space from "@/components/space";
import Button from "@/components/button";
import ListItem from "@/components/list-item";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import { Filter, FilterPopulation, FilterState } from "@/redux/filter.types";
import { filterActivate, filterActivateAndEdit, filterAdd, filterDestroy, filterEdit, filterEditAndUpdate, filterFetch, filterGet, filterRemove, filterStatus, filterUpdate } from "@/redux/filter.actions";
import ModalConfirm from "@/components/modal-confirm";
import PageHeader from "@/components/page-header";
import Card from "@/components/card";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import ListInput from "@/components/list-input";
import SaveIcon from "@/components/save-icon";
import ListButton from "@/components/list-button";
import NavApp from "@/nav/nav.app";
import GetFilterPopulationsModal from "@/modals/get-filter-populations.modal";
import PagePlaceholder from "@/components/page-placeholder";
import Checkbox from "@/components/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USER_LIST_GENDER } from "@/redux/user.types";
import getUserFilterDates, { UserFilterDates } from "@/utils/get-user-filter-dates.utils";
import { accountUpdateOptions } from "@/redux/account.actions";
import { sessionEditAccountOptions } from "@/redux/_session.actions";
import Dropdown from "@/components/dropdown";
import AdmFiltersOrderWidget from "@/widgets/adm-filters-order.widget";

interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
}

const SHOW_DEFAULT_FILTERS: boolean = false
const dateNow: Date = new Date()

//Columns for filter array
const COLUMN_NAME_CHECKBOX: number = 150 //Width of checkbox
const COLUMN_NAME_WIDTH: number = 220 //Width of name column
const COLUMN_POPULATIONS_WIDTH: number = 150 //Width of populatations column

//Modals
const MODAL_DELETE: string = "MODAL_DELETE" //Ask before delete filter
const MODAL_FILTER_POPULATION: string = "MODAL_FILTER_POPULATION"


function AdmFiltersRoute(props:StateProps){
  const { t } = props

  const defaultFilters: Filter[] = [
    new Filter({id: "gender", name: "user_gender", default: true, isVisible: props._session.accountOptions.genderShowFilter, populations: USER_LIST_GENDER.filter((x:any) => x.id !== null).map((x: any) => new FilterPopulation({...x, name: t(x.name), usersCount: 0 }) )}),
    new Filter({id: "birthDate", name: "user_birth_date_alt", default: true, isVisible: props._session.accountOptions.birthDateShowFilter, populations: getUserFilterDates("BIRTH_DATE", dateNow).map((x: UserFilterDates) => new FilterPopulation(x))}),
    new Filter({id: "companyWelcomeDate", name: "user_company_welcome_date_alt", default: true, isVisible: props._session.accountOptions.companyWelcomeDateShowFilter, populations: getUserFilterDates("COMPANY_WELCOME_DATE", dateNow).map((x: UserFilterDates) => new FilterPopulation(x))})
  ]

  //Current modal displayed
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Loading templates
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [currentFilter, setCurrentFilter] = useState<Filter | null>(null)

  //Load filters when page is loaded
  useEffect(() => {
    load()
    async function load(){
      setIsLoading(true)
      const filters:any = await store.dispatch(filterFetch(true, SHOW_DEFAULT_FILTERS))
      store.dispatch(filterGet(filters.error ? [] : filters))
      setIsLoading(false)
    }
  }, [])


  //Add filter
  function addFilter(){
    store.dispatch(filterAdd(new Filter({name: t("filter_new")})))
  }

  //Delete > open confirm dialog
  function deleteFilter(filter){
    store.dispatch(filterActivate(filter))
    setCurrentModal(MODAL_DELETE)
  }

  //Confirm filter delete
  async function deleteFilterConfirm(){
    store.dispatch(filterStatus(STATUS_SAVING))
    const response:any = await store.dispatch(filterDestroy(props.filter.active.id))
    if (response.error){
      store.dispatch(filterStatus(STATUS_SAVE_ERROR))
    }else{
      store.dispatch(filterStatus(STATUS_SAVED))
      store.dispatch(filterRemove(props.filter.active.id))
      setCurrentModal(null)
    }
  }

  //Edit filter name
  function editFilterName(value){
    store.dispatch(filterEditAndUpdate("name", value))
  }

  async function editReferenceFilter(item: {id: string, name: string}){
    store.dispatch(filterStatus(STATUS_SAVING))

    const response: any = await store.dispatch(accountUpdateOptions({
      ...props._session.accountOptions,
      heatmapFilterName: item.name
    }))

    if(response.error){
      store.dispatch(filterStatus(STATUS_SAVE_ERROR))
    }
    else{
      store.dispatch(sessionEditAccountOptions("heatmapFilterName", item.name))
      store.dispatch(filterStatus(STATUS_SAVED))
    }
  }

  async function toggleFilter(filter: Filter){
    store.dispatch(filterStatus(STATUS_SAVING))
    store.dispatch(filterEdit("isVisible", !filter.isVisible))
    filter.isVisible = !filter.isVisible
    store.dispatch(filterActivate(filter))
    if(filter.default){
      store.dispatch(sessionEditAccountOptions(filter.id + "ShowFilter", /*!props._session.accountOptions[filter.id + "ShowFilter"]*/filter.isVisible))
      const response: any = await store.dispatch(accountUpdateOptions(props._session.accountOptions))
      store.dispatch(filterStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
    }
    else{
      const response: any = await store.dispatch(filterUpdate(filter))
      store.dispatch(filterStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
    }
  }

  return (
    <NavApp>

      { /** dialog to confirm delete */
      currentModal === MODAL_DELETE &&
      <ModalConfirm
        onNo={() => setCurrentModal(null)}
        onYes={deleteFilterConfirm}
        status={props.filter.status}
        text={t("filter_ask_delete", {
          filter : props.filter.active.name
        })}
        textBold={t("utils_next_ask")}>
      </ModalConfirm>
      }

      { currentModal === MODAL_FILTER_POPULATION &&
      <GetFilterPopulationsModal
        filter={currentFilter ? currentFilter : undefined}
        onClose={()=>setCurrentModal(null)}
      />
      }

      <PageHeader title={t("filters_custom")}
        subtitle={t("filters_help")}>

        { props.filter.list.length > 0 &&
        <Button onClick={addFilter}
          className="primary">
          {t("filter_add")}
        </Button>
        }

      </PageHeader>

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        { props.filter.list.length > 0 ?
        <Card isWithoutPadding>

          <div className="flex height-20" />

          <div style={{paddingLeft: 30}}>
            <b>{t("filters_custom")}</b>
          </div>

          <div className="flex height-20" />

          <div className="flex" style={{padding: "0"}}>
            <Space />

            <Dropdown
              active={props._session.accountOptions.heatmapFilterName}
              list={props.filter.list.map((filter: Filter) => ({id: filter.name, name: filter.name}))}
              value={props._session.accountOptions.heatmapFilterName}
              displayField="name"
              onSelect={editReferenceFilter}
              isNullAllowed
              title={t("filter_reference")}
              status={props.filter.status}
            />

          </div>

          <ListFields>
            <ListCell width={COLUMN_NAME_WIDTH}>
              {t("filter_name")}
            </ListCell>
            <ListCell width={COLUMN_POPULATIONS_WIDTH}>
              {t("attributes")}
            </ListCell>
            <Space/>
          </ListFields>

          { props.filter.list.filter((x: Filter) => !x.default).map((filter: Filter) =>
          <ListItem key={filter.id}>

            <ListCell width={COLUMN_NAME_WIDTH}>
              <ListInput isEdited={filter.id === props.filter.active.id}
                onEdit={() => store.dispatch(filterActivate(filter))}
                onEditEnd={editFilterName}
                value={filter.name}
              />
            </ListCell>

            <div className="flex1 grey-t flex flex-wrap" style={{minWidth: COLUMN_POPULATIONS_WIDTH}}>
              {filter.populations.slice(0, 3).map(x => x.name).join(", ")}
              {filter.populations.length > 3 && 
              <span>
                ...
              </span>
              }
            </div>

            <ListCell>
              <SaveIcon status={props.filter.active.id === filter.id ? props.filter.status : null }/>
            </ListCell>

            { filter.name === props._session.accountOptions.heatmapFilterName ?
            <ListButton text={t("filter_reference")}
              isInactive
              icon={faStar}
            />
            :
            <ListCell/>
            }
            
            <ListButton text={t("filter_is_manager")}
              icon={faCrown}
              isInactive={!filter.isManager}
              onClick={() => {
                store.dispatch(filterActivateAndEdit(filter, "isManager", !filter.isManager))
                setTimeout(() => {
                  filterUpdate(props.filter.active)
                }, 250)
              }}
            />
            
            <ListButton text={t("show_populations")}
              icon={faEye}
              onClick={() => {setCurrentFilter(filter); setCurrentModal(MODAL_FILTER_POPULATION)}}
            />
            
            <ListButton text={t("filter_delete")}
              icon={faTrash}
              onClick={() => deleteFilter(filter)}
            />

          </ListItem>
          )}

        </Card>
        :
        <Card>
          <PagePlaceholder
            button={t("filter_add")}
            image="sherlock"
            title={t("no_filter")}
            help={t("project_filters_help_1")}
            onClick={addFilter}
          />
        </Card>
        }

        <Card>
          <b>{t("filter_order")}</b>
          <div className="height-20" />
          <AdmFiltersOrderWidget />
        </Card>

        <Card isWithoutPadding>

          <div className="flex height-20" />

          <div style={{paddingLeft:30}}>
            <b>{t("filters_default")}</b>
          </div>

          <ListFields>
            <ListCell width={COLUMN_NAME_CHECKBOX}>
              {t("dashboard_displayed") + " "}
              <div className="flex flex-dcol">
                <Space/>
                <FontAwesomeIcon icon={faInfoCircle} data-tip={t("dashboard_displayed_help")}/>
                <Space/>
              </div>
            </ListCell>
            <ListCell width={40}/>
            <ListCell width={COLUMN_NAME_WIDTH}>
              {t("filter_name")}
            </ListCell>
            <ListCell width={COLUMN_POPULATIONS_WIDTH}>
              {t("attributes")}
            </ListCell>
            <Space/>
          </ListFields>

          { defaultFilters.map((filter: Filter, index: number) =>
          <ListItem key={index}>

            <ListCell width={COLUMN_NAME_CHECKBOX}>
              <Checkbox onClick={props.filter.status === STATUS_SAVING ? null : ()=>toggleFilter(filter)}
                active={filter.isVisible}
              />
              <Space/>
            </ListCell>

            <ListCell width={40}/>

            <ListCell width={COLUMN_NAME_WIDTH}>
              {t(filter.name)}
            </ListCell>

            <div className="flex1 grey-t flex flex-wrap" style={{minWidth:COLUMN_POPULATIONS_WIDTH}}>
              {filter.populations.slice(0, 3).map(x => t(x.name)).join(", ")}
              {filter.populations.length > 3 && 
              <span>
                ...
              </span>
              }
            </div>

            <ListCell>
              <SaveIcon status={props.filter.active.id === filter.id ? props.filter.status : null }/>
            </ListCell>

            <ListCell/>
            
            <ListCell/>

          </ListItem>
          )}
          
        </Card>

      </PageLoader>

    </NavApp>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter
})

export default connect(mapStateToProps)(withTranslation()(AdmFiltersRoute))
