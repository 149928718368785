/** 
 * SUPERVISOR-FEEDBACKS
 * Get list of feedbacks from the users
 */
import { useState, useEffect } from "react"
import { WithTranslation, withTranslation } from 'react-i18next'
import PageHeader from "@/components/page-header";
import { store } from "@/index";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import Card from "@/components/card";
import ListItem from "@/components/list-item";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import Space from "@/components/space";
import { faCaretRight, faStar } from "@fortawesome/free-solid-svg-icons";
import Chip from "@/components/chip";
import PageLoader from "@/components/page-loader";
import NavSupervisor from "@/nav/nav.supervisor";
import { feedbackFetchAll } from "@/redux/feedback.actions";
import { Feedback } from "@/redux/feedback.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = WithTranslation

function SupervisorContent(props: Props){
  const { t } = props

  //Status of the page
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //List of feedbacks to display
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([])

  //Load page on start
  useEffect(() => {

    load()

    async function load(){
      const response:any = await store.dispatch(feedbackFetchAll())
      console.log(response.map(x => new Feedback(x)))
      setFeedbacks(response.error ? [] : response.map(x => new Feedback(x)))
      setIsLoading(true)
    }

  }, [])

  //Chip for the note
  //Display number of stars according the value
  function getChip(value:number | null){
    const width = 120

    if (!value){
      return <div style={{ width }}/>
    }else{

      const starsCount = (value / 25) + 1
      const color = getChipColor(starsCount)

      return (
        <div className="flex"
          style={{ width }}>
          <Chip color={color}>
            { [...Array(starsCount)].map(i =>
            <FontAwesomeIcon key={i}
              icon={faStar}
            />
            )
            }
          </Chip>
        </div>
      )
      
    }
  }

  //Color of the chips
  function getChipColor(starsCount:number){
    if (starsCount > 4){
      return "#20CA7E"
    }else if (starsCount > 3){
      return "#4ce6a1"
    }else if (starsCount > 2){
      return "#ff9500"
    }else{
      return "#eb5a46"
    }
  }

  return(
    <NavSupervisor>
      
      <PageHeader title={t("feedbacks")}
        subtitle={t("feedback_subtitle")}>
      </PageHeader>

      <PageLoader status={isLoading ? STATUS_LOADED : STATUS_LOADING}>

        <Card isWithoutPadding>

          <ListFields>
            <ListCell width={160} text={t("user_lastname")}/>
            <ListCell width={100} text={t("utils_date")}/>
            <ListCell width={100} text={t("feedback_message")}/>
            <Space/>
            <ListCell width={108} text={t("feedback_product")}/>
            <ListCell width={108} text={t("feedback_service")}/>
          </ListFields>

          { feedbacks.map(feedback =>
          <ListItem key={feedback.id}>

              <div style={{ width : 172 }}>
                <div>
                  {feedback.User.username}
                </div>
                <div style={{ fontSize : 12 }}>
                  <FontAwesomeIcon icon={faCaretRight}/>
                  &nbsp;
                  {feedback.User.Account.name}
                </div>               
              </div>

              <ListCell width={100}>
                <span className="grey-t">
                  {feedback.createdAt.toLocaleDateString()}
                </span>
              </ListCell>

              <div className="grey-t flex1"
                style={{ fontSize : 12 }}>
                {feedback.message}
              </div>

              <div className="width-20"/>

              {getChip(feedback.noteProduct)}
              {getChip(feedback.noteService)}

          </ListItem>
          )
          }

        </Card>
        
      </PageLoader>

    </NavSupervisor>
  )

}

export default withTranslation()(SupervisorContent)