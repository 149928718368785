/** 
 * REPORT
 * Survey report config
 */

import i18n from "@/translate/i18n"
import { Page } from "./page.types"
import { Population } from "./population.types"

export type SegmentationType = "gender" | "birthDate" | "companyWelcomeDate" | "customAttributes"

//Advanced options for the report
export class ReportOptions{
  allowObserversToCopy : boolean = false
  description : string = ""
  language : string = i18n.language
  isForPopulations : boolean = false
  populations : Population[] = []
  constructor(item: Partial<ReportOptions> = {}){
    if (!item) item = new ReportOptions()
    item.populations = item.populations ? item.populations.map(x => new Population(x)) : []
    Object.assign(this, item)
  }
}

//Class report
export class Report{
  AccountId : string = ""
  default : boolean = false
  keyDownAllowed : boolean = true //Disabled keydown when text input is focused
  id : string = ""
  isAutoGenerated : boolean = false //Set report as auto generated to trigger modal that will propose to the user to download the file
  name : string = ""
  heatmapLength : number = 0 //Number of rows for the heatmap
  nbPages : number = 0 //Display count of pages
  options : ReportOptions = new ReportOptions()
  navbarOpen : boolean = true //Display the top panel
  Pages : Page[] = []
  pagesOrder : string[] = []
  paramsOpen : boolean = false //Display the right panel to edit report options
  SurveyId : string | null = null
  tagsData : any = {} //All data needed for the report (keys are the PAGE_CONTENT_TAGS (defined in page.types.ts))
  timestamp : string = "0" //Date of creation
  observerId : string | null = null //If report made by an observer

  constructor(item: Partial<Report> = {}){
    if (!item) item = new Report()
    item.options = new ReportOptions(item.options)
    Object.assign(this, item)
  }

  //Get date
  get date(): string{
    return new Date(parseInt(this.timestamp)).toLocaleDateString()
  }
  
}


export interface ReportState{
  active: Report
  list: Report[]
  status: string
  statusMessage : string
}

export const REPORT_ACTIVATE: string = "REPORT_ACTIVATE"
export const REPORT_ADD: string = "REPORT_ADD"
export const REPORT_EDIT: string = "REPORT_EDIT"
export const REPORT_EDIT_DEFAULT:string = "REPORT_EDIT_DEFAULT"
export const REPORT_GET: string = "REPORT_GET"
export const REPORT_INIT: string = "REPORT_INIT"
export const REPORT_REMOVE: string = "REPORT_REMOVE"
export const REPORT_STATUS: string = "REPORT_STATUS"
export const REPORT_STATUS_MESSAGE: string = "REPORT_STATUS_MESSAGE"