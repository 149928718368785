import env from "@/env"
import socketIOClient, { Socket } from "socket.io-client";

export const SOCKET_DELAY: number = 5000 //max delay befor timeout

export function SocketCreate(): Socket{
  return socketIOClient(env.REACT_APP_URL_GA, {
    withCredentials: true,
    auth: {
      token: env.REACT_APP_SOCKET_TOKEN
    },
    autoConnect: false
  })
}

export function SocketEmit(
  socket: Socket,
  destination: string,
  params: any,
  nbTokens: number | undefined = undefined,
  onStream: Function,
  onError?: Function,
  onEnd?: Function
): Function{

  socket.connect()
    
  let tokens: string = ""

  socket.on("error", (error: any) => {
    if(onError) onError(error)
    socket.disconnect()
  })

  socket.on("response", (token: string, finishReason: string | null) => {
    tokens += token
    onStream(tokens)

    if(finishReason === "length" || finishReason === "stop"){
      tokens = ""
      if(onEnd) onEnd(finishReason)
      socket.disconnect()
    }
  })

  socket.timeout(SOCKET_DELAY).emit(
    destination,
    params,
    nbTokens,
    (err: any) => {
      if(err){
        if(onError) onError(err)
        socket.disconnect()
      }
      else {
        if(onError) onError(null)
      }
    }
  )

  return () => { socket.disconnect() }

}

