/** SESSION.ACTIONS
 * Actions liés à la session
 */

import { SESSION_EDIT, SESSION_EDIT_ACCOUNT_COLORS, SESSION_EDIT_ACCOUNT_OPTIONS, SESSION_EDIT_USER_OPTIONS, SESSION_GET, SESSION_INIT, SESSION_SHOW_ERROR } from "./_session.types"

export const sessionEdit = (key, value) => ({
  type : SESSION_EDIT,
  payload : {
    key,
    value
  }
})

export const sessionEditAccountColors = (key, value) => ({
  type : SESSION_EDIT_ACCOUNT_COLORS,
  payload : {
    key,
    value
  }
})

export const sessionEditAccountOptions = (key, value) => ({
  type : SESSION_EDIT_ACCOUNT_OPTIONS,
  payload : {
    key,
    value
  }
})

export const sessionEditUserOptions = (key, value) => ({
  type : SESSION_EDIT_USER_OPTIONS,
  payload : {
    key,
    value
  }
})

export const sessionGet = (session) => ({
  type : SESSION_GET,
  payload : {
    session
  }
})

export const sessionInit = () => ({
  type : SESSION_INIT
})

export const sessionShowError = (message:string) => ({
  type : SESSION_SHOW_ERROR,
  payload : {
    message
  }
})