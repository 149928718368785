/***
 * DASHBOARD-HEADER
 * Header for every dashboard page => container title, filters and navigation
 */
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import PageHeader from "@/components/page-header";
import { Survey, SurveyState } from "@/redux/survey.types";
import { Filter, FilterState } from "@/redux/filter.types";
import DashboardRandomizeWidget from "./dashboard-randomize.widget";
import Chip from "@/components/chip";
import { faCog, faExclamationTriangle, faInfoCircle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import DashboardFiltersWidget from "./dashboard-filters.widget";
import Link from "@/components/link";
import i18n from "@/translate/i18n";
import { useState } from "react";
import DashboardTipsHelp from "@/help/dashboard-tips.help";
import { Session } from "@/redux/_session.types";
import Dropdown from "@/components/dropdown";
import { store } from "@/index";
import { surveyActivate } from "@/redux/survey.actions";
import GetSurveyResultsModal from "@/modals/get-survey-results.modal";

interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
  survey : SurveyState
}

interface OwnProps{
  isPreview? : boolean
  hideFilters? : boolean
  hideRandomize? : boolean
  helpPage? : number
  onOptions? : Function | undefined
  showSurveySelector?: boolean
  title? : string
}

const HELP_DASHBOARD: string = "HELP_DASHBOARD"
const MODAL_GET_RESULTS: string = "MODAL_GET_RESULTS"

type Props = StateProps & OwnProps

function DashboardHeaderWidget(props:Props){
  const { t } = props

  const [currentHelp, setCurrentHelp] = useState<null | string>(null)

  const [currentModal, setCurrentModal] = useState<null | string>(null)

    //Click on the survey
    function selectSurvey(survey: Survey){
      store.dispatch(surveyActivate(survey))
  
      setCurrentModal(MODAL_GET_RESULTS)
    }

  return (
    <div style={{ marginBottom : '20px' }}>

      { currentHelp === HELP_DASHBOARD &&
      <DashboardTipsHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentModal === MODAL_GET_RESULTS &&
      <GetSurveyResultsModal
        onClose={() => setCurrentModal(null)}
      />
      }

      { props.isPreview
      ?
      <h2 style={{ marginBottom : '32px' }}>
        {props.title}
      </h2>
      :
      <PageHeader title={props.title ? t(props.title) : props.survey.active.name}
        subtitle={t("dashboard_overview_title", { name : props.survey.active.name })}>
        
        <DashboardRandomizeWidget hideRandomize={props.hideRandomize}/>

        <div>

          <Link icon={faInfoCircle}
            onClick={() => window.open("https://www.id-tree.com/aide" + (props.helpPage ? "#page=" + props.helpPage : ""))}>
            {t("utils_help")}
          </Link>

          {(props.onOptions && (props._session.userRole === "ADMIN" || props._session.accountOptions.allowObserverSettings)) &&
          <Link icon={faCog}
            onClick={props.onOptions}
          >
            {t("utils_edit_display")}
          </Link>
          }

          { (i18n.language === "fr" && props._session.modules.learning) &&
          <Link icon={faThumbsUp}
            onClick={() => setCurrentHelp(HELP_DASHBOARD)}>
            Afficher les conseils
          </Link>
          }

        </div>

      </PageHeader>
      }

      <div>

        { (props.survey.active.randomData && !props.isPreview) &&
        <div className="flex">
          <Space/>
          <Chip color="#eb5a46"
            icon={faExclamationTriangle}>
            {t("dashboard_test_help")}
          </Chip>
        </div>
        }

        {( props.survey.list.length > 1 && props.showSurveySelector) &&
        <Dropdown
          active={props.survey.active.id}
          displayField="name"
          list={props.survey.list}
          title={t("survey")}
          onSelect={(survey: Survey) => selectSurvey(survey)}
          status={props.survey.status}
          value={props.survey.active.name}
        />
        }
      </div>

      <div className="flex" style={{ width: 1000 }}>

        { !props.hideFilters &&
        <DashboardFiltersWidget 
          currentFilters={props.filter.list.filter((x: Filter) => !x.default)}
          currentSurvey={props.survey.active}
          hideOverflow
          isPreview={props.isPreview}
        />
        }

      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter,
  survey : state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardHeaderWidget))