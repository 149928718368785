//APP.JS
//Init of the react app. Call be index.js

//External imports
import { Suspense } from 'react';
import { Route, Navigate, Routes, BrowserRouter } from 'react-router-dom'
import axios, { AxiosInstance } from 'axios';
import cookie from 'react-cookies';
import { ToastContainer } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer'
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, GoogleAuthProvider } from 'firebase/auth';

//Intenal imports
import env from '@/env';
import logout from '@/utils/logout.utils';

//IDTREE widgets
import ErrorMessage from '@/components/error-message';
import ChatWidget from '@/widgets/chat.widget';
import MobileWarningModal from '@/modals/mobile-warning.modal';

//IDTree Routes
import LoginApp from '@/routes/login-app.route';
import Onboarding from '@/routes/onboarding.route';
import SupervisorAccounts from '@/routes/supervisor-accounts.route';
import SupervisorAccount from '@/routes/supervisor-account.route';
import SupervisorUsers from '@/routes/supervisor-users.route';
import SupervisorLms from '@/routes/supervisor-lms.route';
import SurveyAccount from '@/routes/survey-account.route';
import SurveyTemplate from '@/routes/survey-template.route';
import SurveyTemplates from '@/routes/survey-templates.route';
import LoginPublic from '@/routes/login-public.route';
import LoginMail from '@/routes/login-mail.route';
import LoginCode from '@/routes/login-code.route';
import LoginRegister from '@/routes/login-register.route';
import DashboardRps from '@/routes/dashboard-rps.route';
import LearningHome from '@/routes/learning-home.route';
import LearningEditor from '@/routes/learning-editor.route';
import SuperadminAccounts from '@/routes/superadmin-accounts.route';
import SuperadminUsers from '@/routes/superadmin-users.route';
import LoginSupervisor from '@/routes/login-supervisor.route';
import SuperadminSettings from '@/routes/superadmin-settings.route';
import OnboardingVideo from '@/routes/onboarding-video.route';
import OnboardingSignup from '@/routes/onboarding-signup.route';
import DashboardNpsRoute from '@/routes/dashboard-nps.route';
import ReportEditRoute from '@/routes/report-edit.route';
import ReportListRoute from '@/routes/report-list.route';
import HomeAccountRoute from '@/routes/home-account.route';
import AdmFiltersRoute from '@/routes/adm-filters.route';
import ProjectListRoute from '@/routes/project-list.route';
import ProjectEditRoute from '@/routes/project-edit.route';
import AdmImportManualRoute from '@/routes/adm-import-manual.route';
import AdmImportFileRoute from '@/routes/adm-import-file.route';
import AdmImportMappingRoute from '@/routes/adm-import-mapping.route';
import AdmRolesRoute from '@/routes/adm-roles.route';
import LoginSsoAuthRoute from '@/routes/login-sso-auth.route';
import TemplateEditorRoute from '@/routes/template-editor.route';
import DashboardHeatmapRoute from '@/routes/dashboard-heatmap.route';
import DashboardCompareRoute from '@/routes/dashboard-compare.route';
import AdmSelfRoute from '@/routes/adm-self.route';
import AdmSettingsRoute from '@/routes/adm-settings.route';
import AdmUsersRoute from '@/routes/adm-users.route';
import DashboardHomeRoute from '@/routes/dashboard-home.route';
import DashboardRepartitionRoute from '@/routes/dashboard-repartition.route';
import DashboardMessagesRoute from '@/routes/dashboard-messages.route';
import DashboardOverviewRoute from '@/routes/dashboard-overview.route';
import DashboardRpsTeamRoute from '@/routes/dashboard-rps-team.route';
import DashboardQuestionsRoute from '@/routes/dashboard-questions.route';
import DashboardParticipationRoute from '@/routes/dashboard-participation.route';
import SuperadminTemplatesRoute from '@/routes/superadmin-templates.route';
import ProjectInvitationsRoute from '@/routes/project-invitations.route';
import TasksHomeRoute from '@/routes/tasks-home.route';
import TasksEditRoute from '@/routes/tasks-edit.route';
import ResultsHomeRoute from '@/routes/results-home.route';
import DashboardHistoryRoute from '@/routes/dashboard-history.route';
import SupervisorFeedbacksRoute from '@/routes/supervisor-feedbacks.route';
import SurveyTemplatesPublicRoute from '@/routes/survey-templates-public.route';
import LoginSsoRedirectionRoute from '@/routes/login-sso-redirection.route';
import CommissionsRoute from '@/routes/commissions.route';
import SupervisorTemplatesRoute from '@/routes/supervisor-templates.route';
import SupervisorReportsRoute from '@/routes/supervisor-reports.route';

/* INIT APP */

//Init firebase (auth)
const firebaseApp: FirebaseApp = initializeApp(env.REACT_APP_FIREBASE);
export const firebaseAppAuth: Auth = getAuth(firebaseApp);
export const firebaseProvider: GoogleAuthProvider = new GoogleAuthProvider()

//Init axios (API Rest IDTree)
export const instanceBackend: AxiosInstance = axios.create({
  baseURL: env.REACT_APP_URL_BACKEND,
  timeout: 10000,
  headers: {
    'Content-Type' : 'application/json'
  }
})

//Init axios (AI with Numind)
export const instanceNumind: AxiosInstance = axios.create({
  baseURL: env.REACT_APP_URL_NUMIND,
  timeout: 10000,
  headers: {
    'Content-Type' : 'application/json'
  }
})

//App for qrcode invitations and create survey surveys
export const instanceQrcode: AxiosInstance = axios.create({
  baseURL: env.REACT_APP_URL_QRCODE,
  timeout: 30000,
  headers: {
    'Content-Type' : 'application/json'
  }
})

//Init axios (reporting service)
export const instanceReporting: AxiosInstance = axios.create({
  baseURL: env.REACT_APP_URL_REPORTING,
  timeout: 20000,
  headers: {
    'Content-Type' : 'application/json'
  }
})

//Init firebase token
if (!cookie.load('firebaseToken')){
  cookie.save('firebaseToken', 'null', { path: '/' });
  instanceBackend.defaults.headers.common['firebaseToken'] = 'null';
}else{
  instanceBackend.defaults.headers.common['firebaseToken'] = cookie.load('firebaseToken');
}

//Init idtree token
if (!cookie.load('idtreeToken')){
  cookie.save('idtreeToken', 'null', { path: '/' });
  instanceBackend.defaults.headers.common['idtreeToken'] = 'null';
  instanceQrcode.defaults.headers.common['idtreeToken'] = 'null';
  instanceReporting.defaults.headers.common['idtreeToken'] = 'null';
}else{
  instanceBackend.defaults.headers.common['idtreeToken'] = cookie.load('idtreeToken');
  instanceQrcode.defaults.headers.common['idtreeToken'] = cookie.load('idtreeToken');
  instanceReporting.defaults.headers.common['idtreeToken'] = cookie.load('idtreeToken');
}

//Init reporting token
if (!cookie.load('reportingToken')){
  cookie.save('reportingToken', 'null', { path: '/' });
  instanceReporting.defaults.headers.common['reportingToken'] = 'null';
}else{
  instanceReporting.defaults.headers.common['reportingToken'] = cookie.load('reportingToken');
}

//App React
function App() {

  //Detect if mobile (trigger display error message)
  const isMobile: boolean = false/*(
    typeof window.orientation !== "undefined") || 
    (navigator.userAgent.indexOf('IEMobile') !== -1
  )*/

  //If inactivity
  //Redirection to login page
  const handleOnIdle = () => {
    if (window.location.pathname.indexOf("/login/app") === -1){
      logout(true)
    }
  }

  //Get IdleTimer (30 minutes)
  useIdleTimer({
    timeout: (1000 * 60) * 60 * 12,
    onIdle: handleOnIdle,
    debounce: 500
  })

  //Title of the page
  document.title = 'IDTree';

  return (
    <Suspense fallback="loading">

      <BrowserRouter>

        <div className="flex dark-t">

          { isMobile &&
          <MobileWarningModal/>
          }

          <ChatWidget/>

          <ErrorMessage/>

          <ToastContainer autoClose={8000}
            position="bottom-right"
            hideProgressBar={true}
            theme="colored"
            pauseOnHover>
          </ToastContainer>

          <Routes>

            <Route path="/adm/filters" element={<AdmFiltersRoute/>} />
            <Route path="/adm/import-file" element={<AdmImportFileRoute/>} />
            <Route path="/adm/import-manual" element={<AdmImportManualRoute/>} />
            <Route path="/adm/import-mapping" element={<AdmImportMappingRoute/>} />
            <Route path="/adm/roles" element={<AdmRolesRoute/>} />
            <Route path="/adm/self" element={<AdmSelfRoute/>} />
            <Route path="/adm/settings" element={<AdmSettingsRoute/>} />
            <Route path="/adm/users" element={<AdmUsersRoute/>} />

            <Route path="/commissions" element={<CommissionsRoute/>} />

            <Route path="/dashboard/compare" element={<DashboardCompareRoute/>} />
            <Route path="/dashboard/heatmap" element={<DashboardHeatmapRoute/>} />
            <Route path="/dashboard/history" element={<DashboardHistoryRoute/>} />
            <Route path="/dashboard/home" element={<DashboardHomeRoute/>} />
            <Route path="/dashboard/repartition" element={<DashboardRepartitionRoute/>} />
            <Route path="/dashboard/messages" element={<DashboardMessagesRoute/>} />
            <Route path="/dashboard/overview" element={<DashboardOverviewRoute/>} />
            <Route path="/dashboard/nps" element={<DashboardNpsRoute/>} />
            <Route path="/dashboard/participation" element={<DashboardParticipationRoute/>} />
            <Route path="/dashboard/questions" element={<DashboardQuestionsRoute/>} />
            <Route path="/dashboard/rps" element={<DashboardRps/>} />
            <Route path="/dashboard/rps-team" element={<DashboardRpsTeamRoute/>} />

            <Route path="/results/home" element={<ResultsHomeRoute/>} />

            <Route path="/home/account" element={<HomeAccountRoute/>} />

            <Route path="/learning/home" element={<LearningHome/>} />
            <Route path="/learning/editor" element={<LearningEditor/>} />
          
            <Route path="/login/app" element={<LoginApp/>}/>
            <Route path="/login/code" element={<LoginCode/>}/>
            <Route path="/login/mail" element={<LoginMail/>}/>
            <Route path="/login/public" element={<LoginPublic/>}/>
            <Route path="/login/register" element={<LoginRegister/>}/>
            <Route path="/login/sso/auth/:org" element={<LoginSsoAuthRoute/>}/>
            <Route path="/login/sso/redirection" element={<LoginSsoRedirectionRoute/>}/>
            <Route path="/login/supervisor" element={<LoginSupervisor/>}/>

            <Route path="/onboarding/video/:video" element={<OnboardingVideo/>}/>
            <Route path="/onboarding/:step/:index" element={<Onboarding/>}/>
            <Route path="/onboarding/signup" element={<OnboardingSignup/>}/>

            <Route path="/project/invitations" element={<ProjectInvitationsRoute/>} />
            <Route path="/project/edit" element={<ProjectEditRoute/>} />
            <Route path="/project/list" element={<ProjectListRoute/>} />

            <Route path="/report/edit" element={<ReportEditRoute/>} />
            <Route path="/report/list" element={<ReportListRoute/>} />

            <Route path="/superadmin/accounts" element={<SuperadminAccounts/>} />
            <Route path="/superadmin/settings" element={<SuperadminSettings/>} />
            <Route path="/superadmin/templates" element={<SuperadminTemplatesRoute/>} />
            <Route path="/superadmin/users" element={<SuperadminUsers/>} />

            <Route path="/supervisor/account" element={<SupervisorAccount/>} />
            <Route path="/supervisor/accounts" element={<SupervisorAccounts/>} />
            <Route path="/supervisor/feedbacks" element={<SupervisorFeedbacksRoute/>} />
            <Route path="/supervisor/lms" element={<SupervisorLms/>} />
            <Route path="/supervisor/reports" element={<SupervisorReportsRoute/>} />
            <Route path="/supervisor/templates" element={<SupervisorTemplatesRoute/>} />
            <Route path="/supervisor/users" element={<SupervisorUsers/>} />

            <Route path="/survey/account" element={<SurveyAccount/>} />
            <Route path="/survey/template" element={<SurveyTemplate/>} />
            <Route path="/survey/templates" element={<SurveyTemplates/>} />
            <Route path="/survey/templates/public" element={<SurveyTemplatesPublicRoute/>} />

            <Route path="/tasks/edit" element={<TasksEditRoute/>} />
            <Route path="/tasks/home" element={<TasksHomeRoute/>} />
            
            <Route path="/template/editor" element={<TemplateEditorRoute/>} />

            {/** Default redirect to login page **/}
            <Route path="/" element={<Navigate replace to="/login/app"/>} />
            <Route path="*" element={<LoginApp/>} />

            
          </Routes>

        </div>
        
      </BrowserRouter>

    </Suspense>
  );
}

export default App;
