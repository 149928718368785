/**
 * DASHBOARD-COMPARE-POPULATIONS.WIDGET
 * Compare score between two populations
 */

import { WithTranslation, withTranslation } from "react-i18next"
import DashboardFiltersWidget from "./dashboard-filters.widget"
import { connect } from "react-redux"
import { SurveyState } from "@/redux/survey.types"
import { DashboardFilters, FilterState } from "@/redux/filter.types"
import FilterDescriptionDisplayerWidget from "./filter-description-displayer.widget"
import { useEffect, useState } from "react"
import Space from "@/components/space"
import Button from "@/components/button"
import { fetchAxes, fetchQuestions, fetchTopics } from "@/redux/_archive.actions"
import { store } from "@/index"
import i18n from "@/translate/i18n"
import { Session } from "@/redux/_session.types"
import { INSUFFICIENT_PARTICIPATION, STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import { Axis } from "@/redux/axis.types"
import { Topic, TopicState } from "@/redux/topic.types"
import { Question } from "@/redux/question.types"
import { flatten } from "lodash"
import Dropdown from "@/components/dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortAmountDownAlt, faSortNumericDown } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from 'react-tooltip'
import Checkbox from "@/components/checkbox"
import { getSatisfaction } from "@/utils/satisfaction.utils"
import { AccountScoreResult } from "@/redux/account.types"
import { axisFindTopicAid } from "@/redux/axis.actions"

interface StateProps{
  _session: Session
  filter: FilterState
  survey: SurveyState
  topic: TopicState
}

type Props = StateProps & WithTranslation

type DataToLoad = "axes" | "topics" | "questions"

const DATA_ITEMS: DataToLoad[] = ["topics", "axes", "questions"]

const MIN_DIFFERENCE: number = 5
const COLUMN_WIDTH: number = 140
const ROW_HEIGHT: number = 80
const HIDE_NO_RESULT: boolean = true

function DashboardComparePopulations2Widget(props: Props){
  const { t } = props

  const referenceDate: Date = props.survey.active.dateStart

  const [activeContext, setActiveContext] = useState<1 | 2 | null>(null)

  //item data to display in rows (questions, topics or axes)
  const [dataToLoad, setDataToLoad] = useState<DataToLoad>("questions")

  const [dataColumn1, setDataColumn1] = useState<any>({})

  const [dataColumn2, setDataColumn2] = useState<any>({})

  const [errors, setErrors] = useState<{0: string | null, 1: string | null}>({0: null, 1: null})

  const [isLoading, setIsLoading] = useState<{0: boolean, 1: boolean}>({0: false, 1: false})

  const [needToReload1, setNeedToReload1] = useState<boolean>(true)

  const [needToReload2, setNeedToReload2] = useState<boolean>(true)

  const [biggestDifference, setBiggestDifference] = useState<number>(MIN_DIFFERENCE)

  const [topicList, setTopicList] = useState<Topic[]>([])

  const [questionList, setQuestionList] = useState<Question[]>([])

  const [sort, setSort] = useState<boolean>(false)

  const [referenceColumn, setReferenceColumn] = useState<1 | 2 | null>(2)

  const [mode, setMode] = useState<AccountScoreResult>(props._session.dashboardDisplaySettings.dashboardDisplayMode)

  useEffect(() => {
    setMode(props._session.dashboardDisplaySettings.dashboardDisplayMode)
    if(dataToLoad === "axes") {
      setNeedToReload1(true)
      setNeedToReload2(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props._session.dashboardDisplaySettings.dashboardDisplayMode
  ])

  useEffect(() => {
    setNeedToReload1(true)
  }, [
    props.filter.dashboard,
    dataToLoad
  ])

  useEffect(() => {
    setNeedToReload2(true)
  }, [
    props.filter.dashboardCompare,
    dataToLoad
  ])

  //Load questions on every change on the dashboard filters
  useEffect(() => {
    if(needToReload1 && activeContext !== 1){
      loadData(props.filter.dashboard, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    needToReload1,
    activeContext
  ])

  //Load questions on every change on the dashboard filters
  useEffect(() => {
    if(needToReload2 && activeContext !== 2){
      loadData(props.filter.dashboardCompare, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    needToReload2,
    activeContext
  ])

  useEffect(() => {

    function prepareRows(){
      const topics: Topic[] = []
      const questions: Question[] = []
      switch(dataToLoad){
        case "axes":
          Object.assign(topics, props.topic.list.filter((x: Topic) => x.aid && (Object.keys(dataColumn1).includes(x.aid+"") || Object.keys(dataColumn2).includes(x.aid+""))) )
          setQuestionList([])
          setMax(topics.map((x: Topic) => getNoteDifference(x.aid)) )
          setTopicList(sort ? topics.sort((a: Topic, b: Topic) => getNoteOrder(a.aid, b.aid)) : topics)
        break

        case "topics":
          Object.assign(topics, props.topic.list)
          setQuestionList([])
          setMax(topics.map((x: Topic) => getNoteDifference(x.aid)) )
          setTopicList(sort ? topics.sort((a: Topic, b: Topic) => getNoteOrder(a.aid, b.aid)) : topics)
        break

        case "questions":
          Object.assign(questions, flatten(props.topic.list.map((x: Topic) => x.Questions.map((y: Question) => new Question({
            ...y,
            fk_topic: x.aid
          })))))
          setTopicList([])
          setMax(questions.map((x: Question) => getNoteDifference(x.aid)) )
          setQuestionList(sort ? questions.sort((a: Question, b: Question) => getNoteOrder(a.aid, b.aid)) : questions)
        break

        default:
          setTopicList([])
          setQuestionList([])
        break
      }
    }

    function setMax(values: (number | null)[]){
      const max: number = Math.max(...values.map((y: number | null) => y ? Math.abs(y) : 0))
      if(max > MIN_DIFFERENCE){
        setBiggestDifference(max)
      }
    }

    function getNoteOrder(id1: number | string | null, id2: number | string | null){
      const score1: number | null = getNoteDifference(id1)
      const score2: number | null = getNoteDifference(id2)
  
      return (score1 !== null ? score1 : Infinity) - (score2 !== null ? score2 : Infinity)
    }

    if(Object.keys(dataColumn1).length > 0 && Object.keys(dataColumn2).length > 0){
      prepareRows()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataColumn1,
    dataColumn2,
    sort,
    dataToLoad
  ])

  function loadData(dashboardFilters: DashboardFilters, column: 0 | 1){
    setIsLoading({...isLoading, [column]: true})

    let fetchData: Function = () => {}
    const data: any = {}

    switch(dataToLoad){

      case "topics":
        fetchData = () => store.dispatch(fetchTopics(dashboardFilters, props._session.accountOptions.topicRepartitionCeil))
        break

      case "questions":
        fetchData = () => store.dispatch(fetchQuestions(dashboardFilters, "scale"))
        break

      case "axes":
        fetchData = () => store.dispatch(fetchAxes(dashboardFilters, i18n.language, props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction"))
        break

      default:
        fetchData = () => ({ error: "uncorrect_data_to_load" })
        break

    }

    fetchData().then((res: any) => {
      if(res.error){
        if(res.error === "insufficient_participation"){
          setErrors({...errors, [column]: INSUFFICIENT_PARTICIPATION})
        }
        else{
          setErrors({...errors, [column]: res.error})
        }
      }
      else{
        
        res.forEach((y: any) => {
          if (dataToLoad === "axes"){
            y.aid = axisFindTopicAid(y.aid)
          }
          console.log(y)
          data[y.aid] = {note: y.note, satisfaction: dataToLoad === "axes" ? y.note : getSatisfaction(y.repartition, y.noAnswers ? y.noAnswers : 0)}
        })
        if(column === 0){
          setDataColumn1(data)
        }
        else{
          setDataColumn2(data)
        }
        setErrors({...errors, [column]: null})
      }
    }).catch((error: any) =>
      setErrors({...errors, [column]: error})
    ).finally(() => {
      if(column === 0){
        setNeedToReload1(false)
      }
      else{
        setNeedToReload2(false)
      }

      setIsLoading({...isLoading, [column]: false})
    })

  }

  function displayAxis(axis: Axis | null){
    const axisTopics: Topic[] = axis ? topicList.filter((x: Topic) => x.AxisId === axis.id) : topicList.filter((x: Topic) => x.AxisId === null)
    const axisQuestions: Question[] = flatten(axisTopics.map((x: Topic) => x.Questions))

    return <div className="flex flex-dcol" style={{width: 400}}>

      <Space/>

      <div style={{color: axis ? axis.color : "#b8b8b8"}}>
        {axis ? axis.label : t("utils_undefined")}
      </div>

      <div
        className="_hover flex"
        style={{fontSize: 12, marginTop: 8}}
      >

        <div>{axisTopics.length + " " + t(axisTopics.length > 1 ? "topics" : "topic")}</div>
        <div>{", "}</div>
        <div>{axisQuestions.length + " " + t(axisQuestions.length > 1 ? "questions" : "question")}</div>

      </div>

      <Space/>

    </div>
  }

  function displayTopic(topic: Topic){
    return <div style={{width: 400}}>
      <div style={{color: topic.Axis ? topic.Axis.color : "#b8b8b8", fontSize: 12}}>
        <b>{t("axis")}</b>
        {" : " + (topic.Axis ? topic.Axis.label : t("utils_undefined"))}
      </div>

      <div>{topic.label}</div>

      <div
        className="_hover"
        style={{fontSize: 12, marginTop: 8}}
      >
        {topic.Questions.length + " " + t(topic.Questions.length > 1 ? "questions" : "question")}
      </div>
    </div>
  }

  function displayQuestion(question: Question){
    const topic: Topic | undefined = props.topic.list.find((x: Topic) => x.aid === question.fk_topic)
    const axis: Axis | null = (topic && topic.Axis) ? topic.Axis : null

    return <div style={{width: 400}}>
      <div style={{fontSize: 12, marginBottom: 8}}>

        <div style={{color: (axis ? axis.color : "#b8b8b8")}}>
          <b>{t("axis")}</b>
          {" : " + (axis ? axis.label : t("utils_undefined"))}
        </div>
 
        <div className="grey-t">
          <b>{t("topic")}</b>
          {" : " + (topic ? topic.label : t("utils_undefined"))}
        </div>

      </div>

      <p>{question.label}</p>
    </div>
  }

  function getNoteDifference(aid: string | number | null): number | null{
    if(
      aid
      && dataColumn1[aid]
      && dataColumn1[aid][mode]
      && dataColumn2[aid]
      && dataColumn2[aid][mode]
    ){
      const difference: number = dataColumn2[aid][mode] - dataColumn1[aid][mode]
      return difference
    }
    else{
      return null
    }
  }

  function displayScore(score: number){
    return mode === "note" ? (score/10).toFixed(1) : (score).toFixed(0) + "%"
  }

  function displayDifference(aid: string | number | null){
    const difference: number | null = getNoteDifference(aid)

    if(difference || difference === 0){
      const ratio: number = Math.abs(difference / biggestDifference)
      const width: number = (2 * COLUMN_WIDTH - 50) * ratio

      return <div
        className="flex"
      >
        <div
          className="flex"
          style={{
            width: referenceColumn === 1 ? 2 * COLUMN_WIDTH + 5 : COLUMN_WIDTH + 5,
            paddingLeft: 5,
            backgroundColor: referenceColumn === 1 ? props._session.accountColors.active + "11" : undefined,
            boxShadow: referenceColumn === 1 ? "inset 1px 0px 0px 0px " + props._session.accountColors.active : undefined,
          }}
        >

          <div className="width-10"/>

          {referenceColumn !== 1 && <Space/>}

          <div
            className="flex flex-dcol grey-t"
            style={{ fontSize: 16, fontWeight: "bold" }}  
          >
            <Space/>
            {displayScore(dataColumn1[aid!][mode])}
            <Space/>
          </div>

          <Space/>

          {referenceColumn === 1 &&
          <div className="flex flex-dcol">
            <Space/>

            <div
              className="flex flex-dcol"
              style={{
                width,
                height: 40,
                backgroundColor: (difference < 0 ? "#20ca7e" : (difference > 0 ? "#eb5a46" : "#b8b8b8")) + Math.round(63 + 192 * ratio).toString(16),
                color: "white",
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8
              }}
            >
              <Space/>

              {width > 40 &&
              <div className="flex">
                <Space/>
                {(difference < 0 ? "+" : "-") + displayScore(Math.abs(difference))}
                <div style={{width: 5}}/>
              </div>
              }

              <Space/>
            </div>
 
            <Space/>
          </div>
          }

        </div>

        <div className="flex flex-dcol">
        <Space/>
          {difference === 0 &&
          <div className="rel flex flex-dcol" style={{height: 40}}>
            <div
              className="flex flex-dcol abs"
              style={{
                height: "100%",
                padding: 1,
                left: -5,
                backgroundColor: "#b8b8b8",
                color: "white",
                borderRadius: 8,
                zIndex: 1
              }}
            >
              <Space/>
              {difference}
              <Space/>
            </div>
          </div>
          }
          <Space/>
        </div>

        <div style={{borderRight: "1px solid " + (referenceColumn ? props._session.accountColors.active : "#b8b8b8")}} />

        <div
          className="flex"
          style={{
            width: referenceColumn === 2 ? 2 * COLUMN_WIDTH + 5 : COLUMN_WIDTH + 5,
            paddingRight: 5,
            backgroundColor: referenceColumn === 2 ? props._session.accountColors.active + "11" : undefined,
            boxShadow: referenceColumn === 2 ? "inset -1px 0px 0px 0px " + props._session.accountColors.active : undefined,
          }}
        >

          {referenceColumn === 2 &&
          <div className="flex flex-dcol">
            <Space/>

            <div
              className="flex flex-dcol"
              style={{
                width,
                height: 40,
                backgroundColor: (difference < 0 ? "#eb5a46" : (difference > 0 ? "#20ca7e" : "#b8b8b8")) + Math.round(63 + 192 * ratio).toString(16),
                color: "white",
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8
              }}
            >
              <Space/>

              {width > 40 &&
              <div className="flex">
                <Space/>
                {(difference > 0 ? "+" : "") + displayScore(difference)}
                <div style={{width: 5}}/>
              </div>
              }

              <Space/>
            </div>
 
            <Space/>
          </div>
          }

          <Space/>

          <div
            className="flex flex-dcol grey-t"
            style={{ fontSize: 16, fontWeight: "bold" }}  
          >
            <Space/>
            {displayScore(dataColumn2[aid!][mode])}
            <Space/>
          </div>

          {referenceColumn !== 2 && <Space/>}

          <div className="width-10"/>

        </div>
      </div>
    }
    else{
      return <div
        className="flex"
        style={{width: COLUMN_WIDTH * 3 + 10, marginTop: 10}}
      >
        <Space/>
        {t("utils_unavailable")}
        <Space/>
      </div>
    }
  }

  return(
    <div id="population-comparison-2" className="card-exportable">
      <div className="height-40" />

      <div style={{position: "sticky", top: -20, backgroundColor: "#F9FBFC", zIndex: 2}}>

        <div className="flex" style={{height: 78}} >
        
          <div className="flex" style={{maxWidth: 470, zIndex: 3}}>
            <div style={{marginTop: -12}}>
              <Dropdown active={dataToLoad}
                displayField="name"
                list={DATA_ITEMS.map((x: DataToLoad) => ({id: x, name: t(x)}))}
                title={t("dashboard_heatmap_results_type")}
                status={(isLoading[0] || isLoading[1]) ? STATUS_LOADING : STATUS_LOADED}
                value={t(dataToLoad)}
                onSelect={(item: any) => setDataToLoad(item.id)}
              />
            </div>

            <div className="flex flex-dcol">
              <Space/>

              <div className="flex">

                <div
                  className={"flex1" + (sort ? " _hover" : "")}
                  onClick={sort ? () => setSort(false) : undefined}
                  style={{width: 30, padding: 5, margin: 5, border: "1px solid #b8b8b8", borderRadius: 8}}
                >
                  <FontAwesomeIcon
                    size={"2x"}
                    data-tip={t("template_order")}
                    icon={faSortNumericDown}
                    color={sort ? "#8C8C8C" : props._session.accountColors.active}
                  />
                </div>

                <div
                  className={"flex1" + (sort ? "" : " _hover")}
                  onClick={sort ? undefined : () => setSort(true)}
                  style={{width: 30, padding: 5, margin: 5, border: "1px solid #b8b8b8", borderRadius: 8}}
                >
                  <FontAwesomeIcon
                    size={"2x"}
                    data-tip={t("nps_header")}
                    icon={faSortAmountDownAlt}
                    color={sort ? props._session.accountColors.active : "#8c8c8c"}
                  />
                </div>
              </div>

              <Space/>
            </div>

            <ReactTooltip/>

          </div>

          <Space/>

          <div className="rel"
            style={{
              width: referenceColumn === 1 ? 2 * COLUMN_WIDTH : COLUMN_WIDTH,
              padding: 5,
              borderTopLeftRadius: 8,
              backgroundColor: referenceColumn === 1 ? props._session.accountColors.active + "11" : undefined,
              boxShadow: referenceColumn === 1 ? "inset 1px 1px 0px 0px " + props._session.accountColors.active : undefined,
            }}>

            <div className="abs grey-t"
              style={{
                textAlign: "center",
                marginTop: -26,
                fontSize: 12,
                width: "calc(100% - 10px)"
              }}>
              {t("dashboard_compare_reference_colonne")}
            </div>
            
            <div className="_hover"
              style={{
                height: 66,
                border: "1px solid " +  ( activeContext === 1 ? props._session.accountColors.active : "#b8b8b8" ),
                borderRadius: 8,
                overflowX: "auto",
                backgroundColor : "white",
                color: (activeContext === 1 ? props._session.accountColors.active : undefined)
              }}
              onClick={
                activeContext === 2 ?
                  () => {setActiveContext(null); setTimeout(() => setActiveContext(1), 50)}
                : () => setActiveContext((prevState: number | null) => prevState === 1 ? null : 1)
              }
            >
              <FilterDescriptionDisplayerWidget
                dashboardFilters={props.filter.dashboard}
                referenceDate={referenceDate}
                nbAttributes={3}
                defaultPlaceholder={props._session.accountName.toUpperCase()}
              />

            </div>

            { errors[0] &&
            <p className="red-t">
              <b>{t(errors[0])}</b>
            </p>
            }

            { isLoading[0] &&
            <p>
              <b>{t("utils_loading")}</b>
            </p>
            }

          </div>

          <div style={{borderRight: "1px solid " + (referenceColumn ? props._session.accountColors.active : "#b8b8b8")}} />

          <div
            style={{
              width: referenceColumn === 2 ? 2 * COLUMN_WIDTH : COLUMN_WIDTH,
              padding: 5,
              borderTopRightRadius: 8,
              backgroundColor: referenceColumn === 2 ? props._session.accountColors.active + "11" : undefined,
              boxShadow: referenceColumn === 2 ? "inset -1px 1px 0px 0px " + props._session.accountColors.active : undefined,
            }}
          >
            <div className="_hover dark-t"
              style={{
                height: 66,
                border: "1px solid " + (( referenceColumn === 2 && new DashboardFilters(props.filter.dashboardCompare).isEmpty ) ? props._session.accountColors.cta : (activeContext === 2 ? props._session.accountColors.active : "#b8b8b8")),
                borderRadius: 8,
                overflowX: "auto",
                backgroundColor: (referenceColumn === 2 && new DashboardFilters(props.filter.dashboardCompare).isEmpty) ? props._session.accountColors.cta : "white",
                color: ((referenceColumn === 2 && new DashboardFilters(props.filter.dashboardCompare).isEmpty) ? "white" : (activeContext === 2 ? props._session.accountColors.active : undefined))
              }}
              onClick={
                activeContext === 1 ?
                  () => {setActiveContext(null); setTimeout(() => setActiveContext(2), 50)}
                : () => setActiveContext((prevState: number | null) => prevState === 2 ? null : 2)
              }
            >
              
              <FilterDescriptionDisplayerWidget
                dashboardFilters={props.filter.dashboardCompare}
                referenceDate={referenceDate}
                nbAttributes={3}
                defaultPlaceholder={referenceColumn === 2 ? t("comparator_select_population").toUpperCase() : props._session.accountName.toUpperCase()}
              />

            </div>

            { errors[1] &&
            <p className="red-t">
              <b>{t(errors[1])}</b>
            </p>
            }

            { isLoading[1] &&
            <p>
              <b>{t("utils_loading")}</b>
            </p>
            }

          </div>

        </div>

        { activeContext &&
        <div className="rel">
          <div className="abs"
            style={{
              right: 5,
              borderRadius: 8,
              backgroundColor: "white",
              zIndex: 2,
              border: "1px solid " + props._session.accountColors.active,
              padding: "10px 20px",
              maxHeight: 450,
            }}
          >

            <Checkbox
              active={activeContext === referenceColumn}
              onClick={() => (activeContext === referenceColumn) ? setReferenceColumn(null) : setReferenceColumn(activeContext)}
              text={t("comparator_reference_column", {column: activeContext})}
            />

            <DashboardFiltersWidget
              currentFilters={props.filter.list}
              currentSurvey={props.survey.active}
              isCompare={activeContext === 2 ? true : false}
            />

            <div className="height-20" />

            <div className="flex" style={{zIndex: 3}}>
              <Space/>
              <Button className="primary" onClick={() => setActiveContext(null)}>
                {t("utils_close")}
              </Button>
            </div>

          </div>
        </div>
        }


      </div>

      <div className="flex" style={{height: 60}}>
        <Space/>
        <div
          style={{
            width: referenceColumn === 1 ? 2 * COLUMN_WIDTH + 10 : COLUMN_WIDTH + 10,
            backgroundColor: referenceColumn === 1 ? props._session.accountColors.active + "11" : undefined,
            boxShadow: referenceColumn === 1 ? "inset 1px 0px 0px 0px " + props._session.accountColors.active : undefined
          }}
        />
        <div style={{borderRight: "1px solid " + (referenceColumn ? props._session.accountColors.active : "#b8b8b8")}} />
        <div
          style={{
            width: referenceColumn === 2 ? 2 * COLUMN_WIDTH + 10 : COLUMN_WIDTH + 10,
            backgroundColor: referenceColumn === 2 ? props._session.accountColors.active + "11" : undefined,
            boxShadow: referenceColumn === 2 ? "inset -1px 0px 0px 0px " + props._session.accountColors.active : undefined
          }}
        />
      </div>

      { //get content
      dataToLoad === "questions" ?
        questionList.filter((question: Question) => HIDE_NO_RESULT ? (getNoteDifference(question.aid) || getNoteDifference(question.aid) === 0) : question).map((question: Question, index: number) =>
          <div
            key={question.id}
            className="flex"
            style={{
              minHeight: ROW_HEIGHT,
              paddingLeft: 5,
              backgroundColor: index%2 === 0 ? props._session.accountColors.brandSecondary + "10" : ""
            }}
          >

            {displayQuestion(question)}

            <Space/>
            
            {( !errors[0] && !errors[1] && !isLoading[0] && !isLoading[1] ) &&
              displayDifference(question.aid)
            }

          </div>
        )
      :
        topicList.filter((topic: Topic) => HIDE_NO_RESULT ? (getNoteDifference(topic.aid) || getNoteDifference(topic.aid) === 0) : topic).map((topic: Topic, index: number) =>
          <div
            key={topic.id}
            className="flex"
            style={{
              minHeight: ROW_HEIGHT,
              paddingLeft: 5,
              backgroundColor: index%2 === 0 ? props._session.accountColors.brandSecondary + "10" : ""
            }}
          >

            {dataToLoad === "axes" ? displayAxis(topic.Axis ? topic.Axis : null) : displayTopic(topic)}

            <Space/>

            {( !errors[0] && !errors[1] && !isLoading[0] && !isLoading[1] ) &&
              displayDifference(topic.aid)
            }

          </div>
        )
      }

      <div className="flex" style={{height: 30}}>
        <Space/>
        <div
          style={{
            width: referenceColumn === 1 ? 2 * COLUMN_WIDTH + 10 : COLUMN_WIDTH + 10,
            backgroundColor: referenceColumn === 1 ? props._session.accountColors.active + "11" : undefined,
            boxShadow: referenceColumn === 1 ? "inset 1px -1px 0px 0px " + props._session.accountColors.active : undefined,
            borderBottomLeftRadius: 8
          }}
        />
        <div style={{borderRight: "1px solid " + (referenceColumn ? props._session.accountColors.active : "#b8b8b8")}} />
        <div
          style={{
            width: referenceColumn === 2 ? 2 * COLUMN_WIDTH + 10 : COLUMN_WIDTH + 10,
            backgroundColor: referenceColumn === 2 ? props._session.accountColors.active + "11" : undefined,
            boxShadow: referenceColumn === 2 ? "inset -1px -1px 0px 0px " + props._session.accountColors.active : undefined,
            borderBottomRightRadius: 8
          }}
        />
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  filter: state.filter,
  survey: state.survey,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(DashboardComparePopulations2Widget))