/** SESSION.REDUCERS
 * Update session informations
 */
import i18n from "@/translate/i18n";
import { Session, SESSION_EDIT, SESSION_EDIT_ACCOUNT_COLORS, SESSION_EDIT_ACCOUNT_OPTIONS, SESSION_EDIT_USER_OPTIONS, SESSION_GET, SESSION_INIT, SESSION_SHOW_ERROR } from "./_session.types";

let currentSession:Session
const initialState: Session = new Session()

export default function sessionReducer(
  state = initialState,
  action: any
): Session {
  switch (action.type) {

    case SESSION_EDIT:
      return new Session(Object.assign({}, state, { [action.payload.key] : action.payload.value }))

    case SESSION_EDIT_ACCOUNT_COLORS:
      currentSession = new Session(state)
      currentSession.accountColors = Object.assign(currentSession.accountColors, { [action.payload.key] : action.payload.value })
      return currentSession
    
    case SESSION_EDIT_ACCOUNT_OPTIONS:
      currentSession = new Session(state)
      currentSession.accountOptions = Object.assign(currentSession.accountOptions, { [action.payload.key] : action.payload.value })
      return currentSession
  
    case SESSION_EDIT_USER_OPTIONS:
      currentSession = new Session(state)
      currentSession.userOptions = Object.assign(currentSession.userOptions, { [action.payload.key] : action.payload.value })
      return currentSession

    case SESSION_GET:
      return new Session(action.payload.session)

    //Force language on init
    case SESSION_INIT:
      i18n.changeLanguage(state.language)
      return new Session(state)

    //Display error message on the bottom of the page
    case SESSION_SHOW_ERROR:
      return new Session(Object.assign({}, state, { errorMessage : action.payload.message }))

    default:
      return state

  }

}