/** ARCHIVE.ACTIONS
 * Liste des actions qui déclenchent requête vers backend archive
 */

import { flatten, sortBy, sum } from "lodash"
import fetchChoiceMessages from "@/utils/fetch-choice-messages.utils"
import formatAxisResults from "@/utils/format-axis-results.utils"
import getArchiveProposition from "@/utils/get-archive-proposition.utils"
import getArchiveQuestion from "@/utils/get-archive-question.utils"
import getArchiveTopic from "@/utils/get-archive-topic.utils"
import { axisFindTopicAid, axisGet } from "./axis.actions"
import { Axis } from "./axis.types"
import { DashboardFilters, FilterLite } from "./filter.types"
import { messageGetCount, messageStatus } from "./message.actions"
import { surveyEdit, surveyFetchOne, surveyStatus } from "./survey.actions"
import { topicGet, topicStatus } from "./topic.actions"
import { userFetchByPopulation } from "./user.actions"
import { generateMessages, generateParticipation, generateTopics } from "./_generate.actions"
import { INSUFFICIENT_PARTICIPATION, STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR } from "./_status.types"

//API

export const fetchAttributes = (
  dashboardFilters: DashboardFilters,
  filterName: string | null,
  surveyId?: string,
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/attributes",
    dashboardFilters,
    surveyId,
    data: {
      filterName
    }
  }
})

export const fetchAttributesDefault = (
  dashboardFilters: DashboardFilters,
  birthDateCeils: string,
  companyWelcomeDateCeils: string,
  dateStart: string,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/attributes/default",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      birthDateCeils,
      companyWelcomeDateCeils,
      dateStart
    }
  }
})

export const fetchAxes: any = (
  dashboardFilters: DashboardFilters,
  language: string,
  withSatisfaction: boolean,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/axes",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      withSatisfaction,
      language
    }
  }
})

export const fetchDisparity = (
  dashboardFilters: DashboardFilters,
  filterName: string | undefined,
  questionAid: string,
  attributeAid: string | undefined,
  responseCount: number,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/disparity",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      filterName,
      questionAid,
      attributeAid,
      responseCount
    }
  }
})

export const fetchFilters = (dashboardFilters: DashboardFilters, surveyId?: string) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/filters",
    dashboardFilters,
    surveyId,
  }
})

export const fetchFiltersBySurveys = (surveyIds: string) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/filters/surveys",
    data: {
      surveyIds
    }
  }
})

export const fetchHeatmap = (
  filterName: string,
  resultsType: string,
  language: string,
  noScore: boolean,
  withSatisfaction: boolean,
  dashboardFilters: DashboardFilters,
  dateCeils: string,
  hideStartedForParticipation: boolean,
  showCompany: boolean,
  showPopulation: boolean,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/heatmap",
    surveyId,
    surveyStart,
    dashboardFilters,
    data: {
      filterName,
      resultsType,
      language,
      noScore,
      withSatisfaction,
      dateCeils,
      hideStartedForParticipation,
      showCompany,
      showPopulation
    }
  }
})

export const fetchMessages = (
  dashboardFilters: DashboardFilters,
  fkQuestion,
  fkTopic,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/messages",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      fkQuestion,
      fkTopic
    }
  }
})

export const fetchMessagesCloud = (
  dashboardFilters: DashboardFilters,
  fkQuestion,
  fkTopic,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/messages/cloud",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      fkQuestion,
      fkTopic
    }
  }
})

export const fetchMessagesCount = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/messages/count",
    dashboardFilters,
    surveyId,
    surveyStart
  }
})

export const fetchMessagesSummarized = (
  dashboardFilters: DashboardFilters,
  fkQuestion: string,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/messages/summarize",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      fkQuestion
    }
  }
})

export const fetchNote = (
  dashboardFilters: DashboardFilters,
  withSatisfaction: boolean,
  surveyId?: string,
  surveyStart?: Date,
  overrideDashboardFilters? : boolean
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/note",
    dashboardFilters,
    surveyId,
    surveyStart,
    overrideDashboardFilters,
    data: {
      withSatisfaction,
    }
  }
})

export const fetchParticipation = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date,
  overrideDashboardFilters? : boolean
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/participation",
    dashboardFilters,
    surveyId,
    surveyStart,
    overrideDashboardFilters
  }
})

export const fetchParticipationFull = (
  dashboardFilters: DashboardFilters,
  filterName: string | null,
  dateCeils?: string,
  surveyId?: string,
  surveyStart?: Date,
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/participation/full",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      dateCeils,
      filterName
    }
  }
})

export const fetchPropositions = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/propositions",
    dashboardFilters,
    surveyId,
    surveyStart
  }
})

export const fetchQuestions = (
  dashboardFilters: DashboardFilters,
  type: string | null,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/questions",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      type
    }
  }
})

export const fetchHistory = (dashboardFilters: DashboardFilters, language: string, projectId: string, resultsType: string, withSatisfaction) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/history",
    dashboardFilters,
    data: {
      language,
      projectId,
      resultsType,
      withSatisfaction
    }
  }
})

export const fetchInequalities = (dashboardFilters: DashboardFilters, filterName: string, surveyId?: string, surveyStart?: Date, ceils?: string) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/inequalities",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      filterName,
      surveyStart,
      ceils,
    }
  }
})

export const fetchRepartition = (
  dashboardFilters: DashboardFilters,
  withSatisfaction: boolean,
  filterName: string | null,
  attributeAid: string | null, 
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/repartition",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      withSatisfaction,
      filterName,
      attributeAid
    }
  }
})

export const fetchTopics: any = (
  dashboardFilters: DashboardFilters,
  repartitionCeil: number,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: 'ARCHIVE',
  payload: {
    url: "/topics",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      repartitionCeil
    }
  }
})

export const getHost_Archive = () => ({
  type: 'ARCHIVE',
  payload: {
    url: "/host",
  }
})

export const surveyFetch_Archive = () => ({
  type: 'ARCHIVE',
  payload: {
    url: "/surveys"
  }
})

//THUNK

export const loadMessagesCount: any = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => async (dispatch, getState) => {
  dispatch(messageStatus(STATUS_LOADING))
  const response = getState().survey.active.randomData ?
    await dispatch(generateMessages(dashboardFilters, true)) :
    await dispatch(fetchMessagesCount(dashboardFilters, surveyId, surveyStart))
  dispatch(messageGetCount(!response.error ? response.count : 0))
  dispatch(messageStatus(!response.error ? STATUS_LOADED : STATUS_LOAD_ERROR))
}

export const loadParticipation: any = (
  dashboardFilters: DashboardFilters,
  isPreview: boolean,
  surveyId?: string,
  surveyStart?: Date
) => async (dispatch, getState) => {

  //If isPreview is true get count of recipients for the project
  if (isPreview) {

    //If there is custom Filters that are defined
    //Find matching attributes and sum the users count
    let count = 0
    if (dashboardFilters.customFilters.length) {
      const attributesIds = flatten(dashboardFilters.customFilters.map(x => x.attributesIds))
      const attributes = getState().attribute.list.filter(x => attributesIds.indexOf(x.id) > -1)
      count = sum(attributes.map(x => x.usersCount))
    }
    //If no custom filter are defined but default user search for the count of users
    else if (dashboardFilters.gender || dashboardFilters.birthDate || dashboardFilters.companyWelcomeDate) {

      let defaultFilterId = ""
      let defaultPopulationId = ""
      if (dashboardFilters.gender) {
        defaultFilterId = "gender"
        defaultPopulationId = dashboardFilters.gender
      } else if (dashboardFilters.birthDate) {
        defaultFilterId = "birthDate"
        defaultPopulationId = dashboardFilters.birthDate
      } else if (dashboardFilters.companyWelcomeDate) {
        defaultFilterId = "companyWelcomeDate"
        defaultPopulationId = dashboardFilters.companyWelcomeDate
      }

      const users: any = await dispatch(userFetchByPopulation("", "", "", true, defaultFilterId, defaultPopulationId))
      count = users.length

    }
    else {
      count = getState().user.countSearch
    }

    dispatch(surveyEdit("participationDone", count))
    dispatch(surveyEdit("participationSend", count))
    dispatch(surveyEdit("participationStarted", 0))
    dispatch(surveyEdit("participationUndone", 0))

  }
  //Otherwise load participation or generate data
  else {

    //Load data from server
    dispatch(surveyStatus(STATUS_LOADING))
    const response = getState().survey.active.randomData ?
      await dispatch(generateParticipation(dashboardFilters)) :
      await dispatch(fetchParticipation(dashboardFilters, surveyId, surveyStart))
    dispatch(surveyStatus(!response.error ? STATUS_LOADED : STATUS_LOAD_ERROR))

    //Save each item
    if (!response.error) {
      const items = ["participationDone", "participationSend", "participationStarted", "participationUndone"]
      items.forEach(item => {
        dispatch(surveyEdit(item, response[item] ? response[item] : 0))
      })
    }

  }


}


//Load page dashboard/question
//First get all topics
//THen load quesitons and propositions
export const loadQuestionsAndTopics: any = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => async (dispatch, getState) => {

  dispatch(topicStatus(STATUS_LOADING))

  //Load topic (from archive or generate)
  const response = getState().survey.active.randomData ?
    await dispatch(generateTopics(
      getState().survey.active.template?.id,
      getState().survey.active.test ? getState().survey.active.id : null, //SurveyId, 
      false,
      dashboardFilters
    )) :
    await dispatch(fetchTopics(
      dashboardFilters,
      getState()._session.accountOptions.topicRepartitionCeil,
      surveyId,
      surveyStart
    ))

  if (response.error) {
    if (response.error === INSUFFICIENT_PARTICIPATION) {
      dispatch(topicStatus(INSUFFICIENT_PARTICIPATION))
    }
    else {
      dispatch(topicStatus(STATUS_LOAD_ERROR))
    }
  } else {

    //Fetch axes
    const axes: any = getState().survey.active.randomData ?
      [] :
      await dispatch(fetchAxes(
        dashboardFilters,
        getState()._session.userInitialLanguage,
        getState()._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction",
        surveyId,
        surveyStart
      ))

    //Update axes aid
    if (!axes.error){
      axes.forEach(item => {
        item.aid = axisFindTopicAid(item.aid)
      })
    }

    //Get topics (nested if generate data)
    const topics = getState().survey.active.randomData ? response.topics : response

    //Get questions
    const questions = getState().survey.active.randomData ?
      response.questions :
      await dispatch(fetchQuestions(
        dashboardFilters,
        null,
        surveyId,
        surveyStart
      )).then((response: any) => {
        return response.error ? [] : response
      }).catch((error: any) => {
        console.log(error)
        return []
      })

    //Get propositions
    const propositions = getState().survey.active.randomData ?
      response.propositions :
      await dispatch(fetchPropositions(
        dashboardFilters,
        surveyId,
        surveyStart
      ))

    //Get new topics from old topics list
    const newTopics = getState().topic.list

    //Loop on each topic to add informations from reporting data
    newTopics.forEach(topic => {
      topic.propositionsOverview = []

      //Assocate topic from archive
      topic = getArchiveTopic(topic, topics)

      //Associate questions to get repartition
      topic.Questions.forEach(question => {
        question.open = false

        //match question
        question = getArchiveQuestion(topic, question, questions)

        //Update count for propositions
        question.Propositions.forEach(proposition => {

          //Match proposition
          //For generated data
          //Archive id match with quesiontId and propositionId
          proposition = getArchiveProposition(topic, question, proposition, questions, propositions)

          //Add to overview for 
          //> 30% and more and
          //> primary question
          if (
            !question.primaryId &&
            proposition.getRatio(getState().survey.active.getParticipationCount(getState()._session.dashboardDisplaySettings.hideStartedForParticipation)) > 30
          ) {
            topic.propositionsOverview.push(proposition)
          }

        })

        //Update overview
        //Order by ratio and max 3 responses
        topic.propositionsOverview = sortBy(topic.propositionsOverview, "count").reverse().slice(0, 3)

        //Set axis id if is null
        //Allow to display topics without axis
        if (!topic.AxisId) {
          topic.AxisId = ""
        }

      })

    })

    //Update axes with note (calculte from topic list)
    const newAxes: Axis[] = Array.from(getState().axis.list)

    //Update store
    dispatch(axisGet(newAxes.map(axis => 
      formatAxisResults(axis, newTopics, axes, getState()._session.accountId, getState().survey.active.randomData)
    ), []))
    dispatch(topicGet(newTopics))
    dispatch(topicStatus(!topics.error ? "PAGE_LOADED" : STATUS_LOAD_ERROR)) //PAGE_LOADED (in order to detect page loading)

    //Fetch message for open topics
    newTopics.filter(x => x.open).forEach(topic => {
      fetchChoiceMessages(
        topic,
        dashboardFilters,
        surveyId,
        surveyStart
      )
    })

  }

}

//Load survey and topics (for action plan)
export const loadSurveyAndTopics: any = (
  surveyId: string,
  surveyStart?: Date
) => async (dispatch, getState) => {

  //Init filters and start loading
  let dashboardFilters: DashboardFilters = new DashboardFilters()
  dispatch(topicStatus(STATUS_LOADING))

  const survey: any = surveyId ? await dispatch(surveyFetchOne(surveyId, false)) : {}
  let attributesIds: string[] = []

  if (survey.error || !surveyId) {
    dispatch(topicGet([]))
    dispatch(topicStatus(STATUS_LOADED))
  } else {

    //Fetch attribute in order to filter topic results 
    for (let i = 0; i < getState().user.active.populationsObserver.length; i++) {
      const population = getState().user.active.populationsObserver[i]
      const attributesResponse: any = await dispatch(fetchAttributes(new DashboardFilters(), population.filterName, survey.id))
      if (!attributesResponse.error) {
        attributesIds = attributesIds.concat(attributesResponse.filter(x => x.name === population.name).map(x => x.aid))
      }
    }

    dispatch(topicGet(!survey.error && survey ? survey.template.Topics : []))
    if (getState().user.active.populationsObserver.length > 0) {
      if (attributesIds.length) {
        dashboardFilters = new DashboardFilters({ customFilters: [new FilterLite({ attributesIds })] })
        dispatch(loadQuestionsAndTopics(dashboardFilters, surveyId, surveyStart))
      } else {
        dispatch(topicStatus(STATUS_LOADED))
      }
    } else {
      dispatch(loadQuestionsAndTopics(dashboardFilters, surveyId, surveyStart))
    }

    //Load questions, participation and topics data
    dispatch(loadParticipation(dashboardFilters, surveyId, surveyStart))

  }

}