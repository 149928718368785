/***
 * DASHBOARD-FILTERS
 * List of filters available for dashboard
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { Survey, SurveyState } from "@/redux/survey.types";
import { DashboardFilters, DEFAULT_RANDOM_ATTRIBUTES, Filter, FilterLite, FilterState } from "@/redux/filter.types";
import DashboardFilterWidget from "./dashboard-filter.widget";
import { USER_LIST_GENDER } from "@/redux/user.types";
import { store } from "@/index";
import { Attribute, AttributeState } from "@/redux/attribute.types";
import { useEffect, useState } from "react";
import DashboardCustomFilterWidget from "./dashboard-custom-filter.widget";
import { filterActivateAndEdit, filterCountDefaultPopulations, filterEditDashboard, filterEditDashboardCompare } from "@/redux/filter.actions";
import getUserFilterDates, { UserFilterDates } from "@/utils/get-user-filter-dates.utils";
import { fetchAttributesDefault, fetchFilters } from "@/redux/_archive.actions";
import { Session } from "@/redux/_session.types";
import { flatten, orderBy, snakeCase } from "lodash";
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils";
import Space from "@/components/space";
import { Regrouping, RegroupingState } from "@/redux/regrouping.types";
import { accountUpdateOptions } from "@/redux/account.actions";
import { AccountOptions } from "@/redux/account.types";
import { sessionEditAccountOptions, sessionEditUserOptions } from "@/redux/_session.actions";

/**dnd */
import SortableWidget from "./sortable.widget";
import {
  closestCorners,
  DndContext, 
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { userUpdateOptions } from "@/redux/user.actions";
import { useClickOutside } from "react-click-outside-hook";

interface StateProps extends WithTranslation{
  _session: Session
  attribute: AttributeState
  filter: FilterState
  regrouping: RegroupingState
  survey: SurveyState
}

interface OwnProps{
  currentFilters: Filter[]
  currentSurvey: Survey
  hideOverflow?: boolean
  isPreview?: boolean
  isCompare?: boolean
}

type Props = StateProps & OwnProps

const NB_FILTERS: number = 7 //nb of filter to display if hideOverflow is active

function DashboardFiltersWidget(props:Props){
  const { t } = props

  const [dashboardFilters, setDashboardFilters] = useState<DashboardFilters>(new DashboardFilters(props.isCompare ? props.filter.dashboardCompare : props.filter.dashboard))
  const [currentFilters, setCurrentFilters] = useState<Filter[]>(initCurrentFilters())
  const [populationRepartition, setPopulationRepartition] = useState<any | null>(null)
  const [hideOverflow, setHideOverflow] = useState<boolean>(props.hideOverflow!)

  const [activeId, setActiveId] = useState(null)

  //Detect click outside the component => close context
  const [ref, hasClickedOutside] = useClickOutside()

  const pointerSensor = useSensor(PointerSensor)
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  })
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(
    pointerSensor,
    keyboardSensor,
    mouseSensor,
    touchSensor
  );

  //Trigger close function when clic outside
  useEffect(() => {
    if(hasClickedOutside && props.hideOverflow && !hideOverflow){
      setHideOverflow(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasClickedOutside
  ])

  useEffect(() => {
    async function updateFilters(){

      if(dashboardFilters.isEmpty){
        setCurrentFilters(currentFilters.map((filter: Filter) => new Filter({ ...filter, isVisible: true})))
      }
      else{
  
        //Fetch filters
        const response: any = await store.dispatch(fetchFilters(dashboardFilters, props.survey.active.id))
  
        if (!response.error){
          const activeFilters: {attributesCount: number, name: string}[] = response.reduce((acc: any, res: {attributesCount: number, name: string}) => {
            acc[res.name] = res.attributesCount
            return acc
          }, {})
          
          setCurrentFilters(currentFilters.map((filter: Filter) => new Filter({
            ...filter,
            isVisible: (filter.Attributes.some((attribute: Attribute) => attribute.selected) || filter.Regroupings.some((regrouping: Regrouping) => regrouping.selected)) || (activeFilters[filter.name] && activeFilters[filter.name] > 1)
          })))
        }
      }
  
    }

    updateFilters()

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboardFilters
  ])
  
  useEffect(() => {

    async function fetchDefaultUserCount(){

      const response: any = await store.dispatch((props.isPreview || props.survey.active.id.length < 1) ?
        filterCountDefaultPopulations() :
        fetchAttributesDefault(new DashboardFilters(), getHeatmapCeils("birth_date"), getHeatmapCeils("company_welcome_date"), new Date(props.survey.active.dateStart).toISOString())
      )
      
      if (!response.error){
        setPopulationRepartition(response)
      }

    }

    if (!props.survey.active.randomData || props.isPreview){
      fetchDefaultUserCount()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.survey.active.randomData,
    props.isPreview,
    props.survey.active.dateStart
  ])

  function getBirthDateList(): Attribute[]{
    const birthDateRepartition: any = populationRepartition?.birthDateRepartition

    if(birthDateRepartition){
      return getUserFilterDates("BIRTH_DATE", props.currentSurvey.dateStart).map((x: UserFilterDates, i: number) => new Attribute({...x, usersCount: birthDateRepartition[i]}))
    }
    else{
      return getUserFilterDates("BIRTH_DATE", props.currentSurvey.dateStart).map((x: UserFilterDates) => new Attribute({...x, usersCount: 0}))
    }
  }

  function getCompanyWelcomeDateList(): Attribute[]{
    const companyWelcomeDateRepartition: any = populationRepartition?.companyWelcomeDateRepartition

    if(companyWelcomeDateRepartition){
      return getUserFilterDates("COMPANY_WELCOME_DATE", props.currentSurvey.dateStart).map((x: UserFilterDates, i: number) => new Attribute({...x, usersCount: companyWelcomeDateRepartition[i]}))
    }
    else{
      return getUserFilterDates("COMPANY_WELCOME_DATE", props.currentSurvey.dateStart).map((x: UserFilterDates) => new Attribute({...x, usersCount: 0}))
    }
  }

  function getGenderList(): Attribute[]{
    const genderRepartition: any = populationRepartition?.genderRepartition

    if(genderRepartition){
      return USER_LIST_GENDER.filter((x:any) => x.id !== null).map((x: any) => new Attribute({...x, usersCount: (x.id === "a" ? (populationRepartition.usersCount - ( genderRepartition["f"] + genderRepartition["m"] )) : genderRepartition[x.id]) }) )
    }
    else{
      return USER_LIST_GENDER.filter((x:any) => x.id !== null).map((x: any) => new Attribute({...x, usersCount: 0 }))
    }

  }

  function getFiltersCount(): number{
    let length: number = currentFilters.filter((filter: Filter) => filter.isVisible).length
    if(dashboardFilters.customFiltersIds.length < 1){
      Array.from(["gender", "birthDate", "companyWelcomeDate"]).forEach((x: string) => {
        if(isDefaultFilterActive(x)) length ++
      })
    }

    return length
  }

  function getRandomSelectedIds(): string[]{
    const randomFilter: FilterLite | undefined = dashboardFilters.customFilters.find((x: FilterLite) => x.id === "random")

    if(randomFilter){
      return randomFilter.attributesIds
    }
    else{
      return []
    }
  }

  function initCurrentFilters(): Filter[]{

    //the goal is to set attributes & regroupings for each filter
    //for each regrouping, get their belonging attributes
    //keep only not regrouped attributes in Attributes to avoid duplicates

    const filterOrder: string[] = ( props._session.connectedAsSupervisor || props._session.userOptions.filterOrder.length < 1 ) ? props._session.accountOptions.filterOrder : props._session.userOptions.filterOrder

    return orderBy(
      props.currentFilters.filter((x: Filter) => !props._session.userExcludedFilters.includes(x.name)),
      [
        (filter: Filter) => filterOrder.indexOf(filter.name),
        (filter: Filter) => filter.name
      ],
      [
        "asc",
        "asc"
      ]
    ).map((filter: Filter) => {
      const filterAttributes: Attribute[] = props.attribute.list.filter((attribute: Attribute) => attribute.filterName === filter.name).map((attribute: Attribute) => new Attribute({...attribute, selected: dashboardFilters.customFiltersIds.includes(attribute.id)}))
      const regroupings: Regrouping[] = props.regrouping.list.filter((regrouping: Regrouping) => regrouping.filterName === filter.name).map((regrouping: Regrouping) => { 
        const regroupingAttributes: Attribute[] = filterAttributes.filter((attribute: Attribute) => regrouping.attributes.includes(attribute.id))
        return new Regrouping({
          ...regrouping,
          Attributes: regroupingAttributes,
          selected: regroupingAttributes.some((attribute: Attribute) => attribute.selected)
        })
      })

      let attributes: Attribute[] = []

      if(regroupings.length > 0){
        const regroupedAttributesId: string[] = flatten(regroupings.map((regrouping: Regrouping) => regrouping.attributes))
        attributes = filterAttributes.filter((attribute: Attribute) => !regroupedAttributesId.includes(attribute.id))
      }
      else{
        attributes = filterAttributes
      }

      return new Filter({
        ...filter,
        Attributes: attributes,
        Regroupings: regroupings,
        isVisible: ( attributes.length + regroupings.length ) > 0
      })
    })
    
  }

  //Detect if default filter is available
  function isDefaultFilterActive(filterId: string): boolean{
    if(props._session.accountOptions[filterId + "ShowFilter"] === false){
      return false
    }else if (props.currentSurvey.isSurveyGroup){
      return props.currentSurvey.defaultFilterIds.indexOf(snakeCase(filterId)) > -1
    }else{
      return true
    }
  }

  //Select filter
  //If unique value, take the first one of the array
  function selectFilter(item: string, values: string[], multipleValues: boolean){
    
    let itemIds: any = values
    if (!multipleValues){
      itemIds = itemIds.length ? values[0] : null
    }

    //edit attributes ids list and active filters ids list in dashboard filters
    if(props.isCompare){
      if(props.filter.dashboardCompare[item] !== itemIds){
        store.dispatch(filterEditDashboardCompare(item, itemIds))
      }
    }
    else{
      if(props.filter.dashboard[item] !== itemIds){
        store.dispatch(filterEditDashboard(item, itemIds))
      }
    }

    const newDashboardFilters: DashboardFilters = new DashboardFilters({...dashboardFilters, [item]: itemIds})

    setDashboardFilters(newDashboardFilters)
    
  }

  async function selectCustomFilter(filterId: string, attributesId: string[], regroupingsId: string[]){

    const filters: Filter[] = currentFilters.map((filter: Filter) => filter.id === filterId ?
      new Filter({
        ...filter,
        Attributes: filter.Attributes.map((attribute: Attribute) => new Attribute({ ...attribute, selected: attributesId.includes(attribute.id) })),
        Regroupings: filter.Regroupings.map((regrouping: Regrouping) => new Regrouping({ ...regrouping, attributes: attributesId} ))
      })
    :
      filter
    )

    const selectedIds: string[] = [
      ...attributesId,
      ...regroupingsId
    ]
    
    let newCustomFilters : FilterLite[] = (props.isCompare ? props.filter.dashboardCompare : props.filter.dashboard).customFilters
    
    //if there is at least one attribute selected
    if(selectedIds.length > 0){

      const newFilter: FilterLite =  new FilterLite({
        id: filterId,
        attributesIds : selectedIds, 
        subFilter : props._session.accountOptions.isObserverNotRestricted && props._session.userRole === "OBSERVER" && props.filter.observerAttributes.length > 0
      })

      //replace it if already in the list
      if(newCustomFilters.find((x: FilterLite) => x.id === filterId)){
        newCustomFilters = newCustomFilters.map((x: FilterLite) =>
          x.id === filterId ? newFilter : x
        )
      }
      //or add it
      else{
        newCustomFilters.push(newFilter)
      }
    }
    
    //else remove filter from the list
    else{
      newCustomFilters = newCustomFilters.filter((filterLite: FilterLite) =>
        filterLite.id !== filterId
      )
    }

    if(props.isCompare){
      store.dispatch(filterEditDashboardCompare("customFilters", newCustomFilters))
    }
    else{
      store.dispatch(filterEditDashboard("customFilters", newCustomFilters))
    }

    const newDashboardFilters: DashboardFilters = new DashboardFilters({...dashboardFilters, customFilters: newCustomFilters})

    setCurrentFilters(filters)
    setDashboardFilters(newDashboardFilters)

  }

  function handleDragStart(event) {
    const {active} = event;
    
    setActiveId(active.id);
  }
  
  function handleDragEnd(event: any) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      setCurrentFilters((filters: Filter[]) => {
        const oldIndex = filters.map((x: Filter) => x.id).indexOf(active.id)
        const newIndex = filters.map((x: Filter) => x.id).indexOf(over.id)

        const newFilterOrder: Filter[] = arrayMove(filters, oldIndex, newIndex)

        const newAccountOptions: AccountOptions = new AccountOptions({
          ...props._session.accountOptions,
          filterOrder: newFilterOrder.map((x: Filter) => x.id)
        })

        if(props._session.connectedAsSupervisor){
          store.dispatch(accountUpdateOptions(newAccountOptions))
          store.dispatch(sessionEditAccountOptions("filterOrder", newFilterOrder.map((x: Filter) => x.id)))
        }
        else{
          store.dispatch(userUpdateOptions(props._session.userId, newAccountOptions))
          store.dispatch(sessionEditUserOptions("filterOrder", newFilterOrder.map((x: Filter) => x.id)))
        }
        return newFilterOrder
      })
    }
    
    setActiveId(null)

  }

  return (
    <div ref={ref} className="rel flex" style={{height: props.hideOverflow ? 50 : undefined, width: "100%"}}>

      <div
        className={"medgrey-b flex flex-wrap" + (props.hideOverflow ? " abs" : "")}
        style={{
          padding: "6px 14px",
          backgroundColor: "#F9FBFC",
          zIndex: 5,
          borderBottom: hideOverflow ? undefined : "1px solid"
        }}
      >

        <div
          className="medgrey-t"
          style={{
            fontSize: 12,
            width: 60,
            marginTop: "auto",
            marginBottom: "auto"
          }}
        >
          {t("dashboard_filters")}
        </div>

        <div className="width-20"/>
        
        { /** display sample filter if random Data */
        (props.survey.active.randomData && !props.isPreview)
        ?
        <DashboardFilterWidget 
          filterId="random"
          list={DEFAULT_RANDOM_ATTRIBUTES.map(x => new Attribute(x))}
          name={t("filter_random_title")}
          onSelect={(items) => selectCustomFilter("random", items, [])}
          selectedIds={getRandomSelectedIds()}
        />
        :
        <DndContext 
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext 
            items={currentFilters.filter((filter: Filter) => filter.isVisible).slice(0, hideOverflow ? NB_FILTERS : undefined)}
            strategy={rectSortingStrategy}
          >
            { currentFilters.filter((filter: Filter) => filter.isVisible).slice(0, hideOverflow ? NB_FILTERS : undefined).map((filter: Filter, index: number) =>
            <SortableWidget
              id={filter.id}
              key={filter.id}
              withHandle
              isDisabled={hideOverflow}
            >
              <div style={{ animation: index >= NB_FILTERS ? "fadeIn 2s" : undefined }}>
                <DashboardCustomFilterWidget
                  key={filter.id}
                  attributes={filter.Attributes}
                  regroupings={filter.Regroupings}
                  surveyId={props.currentSurvey.id}
                  filterId={filter.id}
                  filterName={filter.name}
                  onOpen={filter.isChecked ? undefined : () => store.dispatch(filterActivateAndEdit(filter, "isChecked", true))}
                  onSelect={(attributesId: string[], regroupingsId: string[]) => selectCustomFilter(filter.id, attributesId, regroupingsId)}
                  isChecked={filter.isChecked}
                  isPreview={props.isPreview}
                  isCompare={props.isCompare}
                  showInfo={props.isPreview}
                />
              </div>
            </SortableWidget>
            )}

            { dashboardFilters.customFiltersIds.length < 1 &&
            <>
              {( (!hideOverflow || NB_FILTERS - currentFilters.filter((filter: Filter) => filter.isVisible).length > 0) && isDefaultFilterActive("gender") ) &&
              <div style={{ animation: "fadeIn 2s" }}>
                <DashboardFilterWidget isUnique
                  filterId="gender"
                  showInfo={props.isPreview}
                  list={getGenderList()}
                  name={t("user_gender")} 
                  onSelect={(items) => selectFilter("gender", items, false)}
                  selectedIds={dashboardFilters.gender ? [dashboardFilters.gender] : []}
                />
              </div>
              }

              {( (!hideOverflow || NB_FILTERS - currentFilters.filter((filter: Filter) => filter.isVisible).length > 1) && isDefaultFilterActive("birthDate") ) &&
              <div style={{ animation: "fadeIn 2s" }}>
                <DashboardFilterWidget isUnique
                  filterId="birthDate"
                  showInfo={props.isPreview}
                  list={getBirthDateList()}
                  name={t("user_birth_date_alt")}
                  onSelect={(items) => selectFilter("birthDate", items, false)}
                  selectedIds={dashboardFilters.birthDate ? [dashboardFilters.birthDate] : []}
                />
              </div>
              }
              
              {( (!hideOverflow || NB_FILTERS - currentFilters.filter((filter: Filter) => filter.isVisible).length > 2) && isDefaultFilterActive("companyWelcomeDate") ) &&
              <div style={{ animation: "fadeIn 2s" }}>
                <DashboardFilterWidget isUnique
                  showInfo={props.isPreview}
                  filterId="companyWelcomeDate"
                  list={getCompanyWelcomeDateList()}
                  name={t("user_company_welcome_date_alt")}
                  onSelect={(items) => selectFilter("companyWelcomeDate", items, false)}
                  selectedIds={dashboardFilters.companyWelcomeDate ? [dashboardFilters.companyWelcomeDate] : []}
                />
              </div>
              }
            </>
            }

          </SortableContext>

          {
          <DragOverlay>
            {activeId ?
              <div className="filters-item" style={{opacity:0.5}}>
                {activeId}
              </div>
            :
              null
            }
          </DragOverlay>
          }

        </DndContext>
        }

        {( props.hideOverflow && getFiltersCount() > NB_FILTERS ) &&
        <>
          { hideOverflow ?
          <div className="_hover" onClick={() => setHideOverflow(false)}>
            <div className="abs"
              style={{
                marginLeft: -100,
                height: 50,
                width: 100,
                background: "linear-gradient(90deg, #fcfcfc00 30%, #fcfcfcbb 60%, #fcfcfcff 80%)"
              }}
            />
          </div>
          :
          <>
            <Space/>

            <div className="flex flex-dcol">
              <Space/>

              <u className="_hover grey-t"
                onClick={() => setHideOverflow(true)}
              >
                {t("utils_hide")}
              </u>

              <Space/>
            </div>

            <div className="width-10" />
          </>
          }

        </>
        }

      </div>

      {( props.hideOverflow && hideOverflow && getFiltersCount() > NB_FILTERS ) &&
      <div className="flex" style={{width: "100%"}}>
        <Space />
        <div
          className="flex"
          style={{
            backgroundColor: "#fcfcfc",
            zIndex: 5,
            paddingLeft : 16,
            paddingTop : 10
          }}
        >
          <div className="flex flex-dcol">
            <Space/>

            <div className="_hover grey-t"
              onClick={() => setHideOverflow(false)}
            >
              <u>{`${t("utils_show")}`}</u>{` (${(getFiltersCount() - NB_FILTERS)})`}
            </div>

            <Space/>
          </div>
        </div>

        <div className="width-10" />
      </div>       
      }
    </div>

  )
}

const mapStateToProps = state => ({
  _session: state._session,
  attribute: state.attribute,
  filter: state.filter,
  regrouping: state.regrouping,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardFiltersWidget))