/** 
 * LOGIN.MODAL
 * Connexion to the app
 */

import cookie from 'react-cookies';
import { withTranslation, WithTranslation } from "react-i18next"
import LoadingModal from "./loading.modal"
import { useEffect } from "react"
import { store } from '@/index';
import { firebaseAppAuth, firebaseProvider, instanceBackend } from '@/App';
import { _authEmail, _authPassword, _authSsoAuthenticate, _authSupervisor } from '@/redux/_auth.actions';
import { accountConnect, accountConnect_AsSupervisor } from '@/redux/account.actions';
import handleLogin from '@/utils/handle-login.utils';
import { INTERFACE_TYPES } from '@/redux/_session.types';
import i18n from '@/translate/i18n';
import { toast } from 'react-toastify';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import env from '@/env';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

interface OwnProps{
  accountIdForSuperadmin? : string //Id of the account to connect as superamdin
  authLevel : number //0 = public, 1 = email, 2 = password
  code? : string //Code for supervisor
  email? : string //Email (optional)
  isFirstConnexion? : boolean //Is the user connected for the first time ?
  isSupervisor? : boolean //Connect as supervisor user (idtree customer success)
  password? : string //Password (optional)
  onClose? : Function //Trigger fonction on close
  toSupervisor? : boolean //Login to supervisor interface
  sso? : boolean //Auth with SSO
  withGoogle? : boolean //Login with google
}

type Props = OwnProps & WithTranslation

function LoginModal(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  useEffect(() => {

    //Auth SSO
    async function authWithSSO(){
      const queryParams: URLSearchParams = new URLSearchParams(window.location.search)
      const code: string | null = queryParams.get("code")
      if (!code){
        return "no_auth_code"
      }else{
        const response:any = await store.dispatch(_authSsoAuthenticate(code))
        if (response.qvCode && !response.user.role){
          window.location.href = env.REACT_APP_URL_QUICKVOTE + "/" + response.qvCode

          await new Promise(resolve => {
            setTimeout(() => {
              resolve(true)
            }, 1000)
          })

        }else{
          return response
        }
      }
    }

    //Auth with firebase
    async function authWithFirebase(email, password){
      try{
        const firebaseResponse:any = await signInWithEmailAndPassword(firebaseAppAuth, email, password)
        const firebaseToken = await firebaseResponse.user.getIdToken()
        cookie.save('firebaseToken', firebaseToken, { path: '/' })
        instanceBackend.defaults.headers.common['firebaseToken'] = firebaseToken
        return null
      }catch(error:any){
        return t("firebase_" + error.code)
      }
    }
    
    //Auth with google
    async function authWithGoogle(){
      try {
        const googleResponse:any = await signInWithPopup(firebaseAppAuth, firebaseProvider)
        if (firebaseAppAuth.currentUser){
          const firebaseToken = await firebaseAppAuth.currentUser.getIdToken()
          cookie.save('firebaseToken', firebaseToken, { path: '/' })
          instanceBackend.defaults.headers.common['firebaseToken'] = firebaseToken
        }
        return googleResponse.user.email
      }catch(error:any){
        return { error : t("firebase_" + error.code) }
      }
    }

    async function login(){

      //Init error of connexion
      let error : string | null = null

      //Response of the idtree backend
      let response:any = null
      let email = props.email

      //Get URL params
      const searchParams = new URLSearchParams(window.location.search)
      const id = searchParams.get("id") //Login with user id (for observer)
      const isAdmin = searchParams.get("isAdmin") //Connected as admin (hide)
      const lg = searchParams.get("lg") //Specify language

      //If email and password provided auth with firebase
      if (props.authLevel === 2){

       //Signup with google
        if (props.withGoogle){
          const googleResponse = await authWithGoogle()
          if (googleResponse.error){
            error = googleResponse.error
          }else{
            email = googleResponse
          }
        }
        //Signup login/password
        else if (email && props.password){
          error = await authWithFirebase(email, props.password)
          if (error){
            toast(error, { type : "error" })
          }
        }

        //Specific case for the SSo
        if (props.sso){
          response = await authWithSSO()
        }
        //If firebase auth is valid auth to backend
        else if (!error){

          if (props.toSupervisor){
            response = await store.dispatch(_authSupervisor(email, props.code))
          } else if (props.accountIdForSuperadmin){
            const connectFunction = store.getState()._session.interfaceType === "SUPERVISOR" ? accountConnect_AsSupervisor : accountConnect
            response = await store.dispatch(connectFunction(props.accountIdForSuperadmin, email))
          } else{
            response = await store.dispatch(_authPassword(email))
          }

        }

      }
      //Auth with user id
      else if (props.authLevel === 1){

        if (!id){
          error = t("utils_token_empty")
        }else{
          response = await store.dispatch(_authEmail(id))
          response.asAdmin = isAdmin
        }
      }

      //If no response was provided
      if (!response){
        response = {
          user : {
            level : 2
          },
          account : {}
        }
      }

      //Handle response
      if (response.error){
        error = t(response.error)
      }
      
      //End of login if no error
      if (response.redirection){
        i18n.changeLanguage(response.language === "fr" ? "fr" : "en")
        navigate(response.redirection)
      }else if (!error){

        let interfaceType:INTERFACE_TYPES = "ADMIN"
        if (props.toSupervisor) interfaceType = "SUPERVISOR"

        const redirection = await handleLogin(
          response,
          interfaceType, 
          props.isSupervisor ? true : false,
          props.authLevel,
          searchParams, 
          lg,
          props.isFirstConnexion ? true : false
        )
        
        if (redirection){
          navigate(redirection)
        }

      }

      if (props.onClose){
        props.onClose()
      }
    }

    login()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoadingModal/>
  )

}

export default withTranslation()(LoginModal)